(function () {
    'use strict';
    angular.module('myApp.resetPassword', ['ui.router', 'myApp.dataCollector', 'myApp.auth'])

        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider
                    .state('reset', {
                        url: '/reset?token',
                        params: {
                            token: null
                        },
                        templateUrl: 'reset_password/reset_password.html',
                        controller: 'ResetCtrl',
                        controllerAs: 'ctrl'
                    })
                    .state('reset_success', {
                        url: '/reset-success',
                        templateUrl: 'reset_password/reset_password_success.html',
                        controller: 'ResetSuccessCtrl',
                        controllerAs: 'ctrl',
                        resolve: {
                            email: [
                                'DataCollector', function (DataCollector) {
                                    var data = DataCollector.getData();
                                    return data.user;
                                }
                            ]
                        }
                    });
            }
        ])

        .controller('ResetCtrl', [
            '$state', '$stateParams', 'DataCollector', 'Auth', function ($state, $stateParams, DataCollector, Auth) {
                var ctrl = this;
                ctrl.formSubmitted = false;
                ctrl.stepData = DataCollector.getData();
                var reset_token = decodeURIComponent($stateParams.token);
                ctrl.onSubmit = function (form) {

                    if (form.$invalid) {
                        ctrl.formSubmitted = true;
                    } else {

                        Auth.reset(ctrl.stepData.password, reset_token)
                            .then(function () {
                                $state.go('reset_success');
                            }, function (error) {
                                ctrl.error = error;
                            });
                    }
                };
            }
        ])

        .controller('ResetSuccessCtrl', [
            'email', '$state', function (email, $state) {

            }
        ]);
}());

