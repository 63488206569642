(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('recovery_password/recovery_password_success.html',
    '<div class="flex-container" style="min-height: 45vh"><div class="flex-column"><div class="flex-item"><h4 class="bold">Te hemos enviado el acceso a tu zona privada</h4><h3>{{ ctrl.email }}</h3></div><div class="flex-item"><p>Busca en tu correo el email de acceso a Helloteca y pincha en el enlace para continuar con el proceso.</p></div></div></div>');
}]);
})();
