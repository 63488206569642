(function () {
    'use strict';
    angular.module('spinner', [])
        .config([
            '$httpProvider', function ($httpProvider) {
                $httpProvider.interceptors.push('spinnerInterceptor');
            }
        ])
        .factory('spinnerInterceptor', [
            '$q', '$rootScope', function ($q, $rootScope) {
                var showSpinner = true;

                return {
                    request: function (config) {
                        if (showSpinner && config.url.indexOf('helloteca') >= 0) {
                            $rootScope.$broadcast('loading:show', true);
                        }
                        return config;
                    },
                    response: function (response) {
                        if (!response || !response.config || response.config.url.indexOf('helloteca') >= 0) {
                            $rootScope.$broadcast('loading:hide');
                        }
                        return response;
                    },
                    'responseError': function (rejection) {
                        if(rejection.data) {
                            console.error('Error on response to ' + rejection.config.url, rejection.data.errors);
                        } else {
                            console.error('Unexpected error', rejection);
                        }

                        $rootScope.$broadcast('loading:hide');
                        return $q.reject(rejection);
                    },
                    'requestError': function (rejection) {
                        $rootScope.$broadcast('loading:hide');
                        return $q.reject(rejection);
                    },
                    disableSpinner: function () {
                        showSpinner = false;
                    },
                    enableSpinner: function () {
                        showSpinner = true;
                    }
                };
            }
        ])
        .run([
            '$rootScope', function ($rootScope) {
                var requests = [];
                $rootScope.$on('loading:show', function (e, queue) {
                    if(queue) requests.push(1);
                    angular.element('.loading').show();
                });

                $rootScope.$on('loading:hide', function () {
                    requests.pop();
                    if (!requests.length) {
                        angular.element('.loading').hide();
                    }
                });

                $rootScope.$on('$stateChangeSuccess', function () {
                    $rootScope.$emit('loading:hide');
                });
            }
        ]);

}());