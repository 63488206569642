(function () {
    'use strict';
    angular.module('myApp.API', ['restangular', 'myApp.auth'])

        .service('API', [
            'Restangular', '$q', 'Auth',
            function (Restangular, $q, Auth) {

                var self = this;
                var cursor = null;

                function getHeaders(headers) {
                    headers = headers || {};

                    headers['X-WEB-KEY'] = 'HellotecaWebApp';

                    var dstoken = Auth.getToken();
                    if (dstoken) {
                        headers["X-DS-TOKEN"] = dstoken;
                    }
                    return headers;
                }
                function getHeadersnew(headers) {
                    headers = headers || {};

                    headers['X-WEB-KEY'] = 'prod_public_develop_team_SgXI1OWThd2NhVYnsG1c0fOLky4R';
                   
                    var dstoken = Auth.getToken();
                    if (dstoken) {
                        headers["X-DS-TOKEN"] = dstoken;
                    }
                    return headers;
                }

                function parseError(remoteResponse) {
                    switch (remoteResponse.code) {
                        case 'auth-already-exist':
                            return 'El usuario ya existe';
                        case 'user-already-exist':
                            return 'El usuario ya está en uso';
                        case 'security-error':
                            return 'La contraseña no es correcta';
                        case 'not-found':
                            return 'El usuario no existe';
                        case 'recovery_token-already-used':
                            return 'Este enlace ya ha sido utilizado. Puedes solicitar un nuevo correo electrónico para recuperar tu contraseña.';
                        case 'not-allowed':
                            return 'Ya existe un usuario con este email';
                        default:
                            if (remoteResponse.errors && remoteResponse.errors.length) {
                                return remoteResponse.errors[0];
                            }
                            return '¡Lo sentimos! A ocurrido un error. Por favor, inténtalo más tarde.';
                    }
                }


                self.initPreScoring = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/prescoring')
                            .customPOST({
                                origen: projectKeys.source,
                                utm_source: projectKeys.utms.utm_source,
                                utm_campaign: projectKeys.utms.utm_campaign,
                                utm_medium: projectKeys.utms.utm_medium,
                                utm_content: projectKeys.utms.utm_content,
                                utm_term: projectKeys.utms.utm_term
                            }, 'init', {}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.PreScoring.token_id);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendNewUserData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score')
                            .customPOST(data, 'users', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {

                                reject(parseError(response.data));
                            });
                    });
                };
                self.sendUpdateUserData = function (id, data) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/users')
                            .customPUT(data, id, {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.sendUpdateMySelfData = function (data) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/users/')
                            .customPUT(data, 'myself', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    self.getDocumentMyself()
                                        .then(function(user) {
                                            resolve(user);
                                        });
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.prepareDocuments = function (type, data) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/prepare_documents/')
                            .customPOST(data, type, {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.sendUserData = function (data) {
                    console.log(data);
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                 console.log(response);
                                if (response.code == 201 || response.code == 200 || response.code == 'ok') {
                                   console.log('9');
                                    resolve(response); 
                                } else {
                                    console.log('8');
                                    reject(parseError(response.data));
                                     
                                }
                            })
                            .catch(function (response) {
                                console.log(response);
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendHouseData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendEconomicData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendFunel = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getPreScoring = function (token) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/prescoring')
                            .get({token_id: token}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.PreScoring);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getCurrentData = function (token) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/prescoring')
                            .get({token_id: token}, getHeaders())
                            .then(function (response) {
                                if(response.code == 201 || response.code == 200 || response.code == 'ok') {
                                    resolve(response.data.PreScoring);
                                } else {
                                    reject(parseError(response.data));
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getCurrentDataLogin = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/scoring/')
                            .customGET('data', {}, getHeaders({'Content-Type': "application/x-www-form-urlencoded'"}))
                            .then(function (response) {
                                if(response.code == 201 || response.code == 200 || response.code == 'ok') {
                                    resolve(response.data.Scoring);
                                } else {
                                    reject(parseError(response.data));
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getDocumentMyself = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/users/')
                            .customGET('myself', {}, getHeaders())
                            .then(function (response) {
                                if(response.code == 201 || response.code == 200 || response.code == 'ok') {
                                    resolve(response.data.HellotecaUser);
                                } else {
                                    reject(parseError(response.data));
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.UploadFile = function (url, files) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .oneUrl({}, url)
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.code == 'ok' || response.success || response.code == 201) {
                                    var uploadUrl = response.data.uploadUrl;
                                    var formData = new FormData();
                                    formData.append('files', files);
                                    Restangular
                                        .oneUrl({}, uploadUrl)
                                        .customPOST(formData, '', undefined, getHeaders({
                                            'content-Type': undefined
                                        }))
                                        .then(function (response) {
                                            if (response.status == 201 || response.success || response.code == 201) {
                                                self.getDocumentMyself().then(function(myselfData) {
                                                    resolve(myselfData);
                                                });
                                            } else {
                                                if (response.errors && response.errors.length) {
                                                    reject(parseError(response.data));
                                                } else {
                                                    reject('Error al subir archivo');
                                                }
                                            }

                                        });

                                } else {
                                    if (response.errors && response.errors.length) {
                                        reject(parseError(response.data));
                                    } else {
                                        reject('Error al subir archivo');
                                    }
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.deleteDocument = function (id) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/documents/myself/id/')
                            .customDELETE(id, {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    self.getDocumentMyself().then(function(myselfData) {
                                        resolve(myselfData);
                                    });
                                }
                            })
                            .catch(function (response) {

                                reject(parseError(response.data));
                            });
                    });
                };
                self.getResultBancos = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/scoring/')
                            .customGET('data', {}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.HellotecaUser);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getCCAAs = function () {
                    return $q(function (resolve, reject) {
                       $.ajax({
                            url: "https://api.helloteca.com/public/variables?vars=ccaa",
                            headers: getHeadersnew(),
                            type: "GET",
                        }).then(function (response) {
                            if (response.errors && response.errors.length) {
                                reject(parseError(response.data));
                            } else {
                                resolve(response.data.ccaa);
                            }

                        })
                        .catch(function (response) {
                            reject(parseError(response.data));
                        });
                      /*var params={vars:'ccaa'};
                        Restangular
                            .oneUrl('ccaa','https://api.helloteca.com/public/')
                            .customGET('variables', params, getHeadersnew())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.ccaa);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });*/
                            
                    });
                };
                self.getEstadoCivil = function () {
                    return $q(function (resolve, reject) {
                        $.ajax({
                            url: "https://api.helloteca.com/public/variables?vars=estado_civil",
                            headers: getHeadersnew(),
                            type: "GET",
                        }).then(function (response) {
                            if (response.errors && response.errors.length) {
                                reject(parseError(response.data.estado_civil));
                            } else {
                                resolve(response.data.ccaa);
                            }

                        })
                        .catch(function (response) {
                            reject(parseError(response.data));
                        });
                        /*
                        Restangular
                            .one('/helloteca/score/variables/estado_civil')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.estado_civil);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                        */
                    });
                };
                self.getTipoVivienda = function () {
                    return $q(function (resolve, reject) {
                        $.ajax({
                            url: "https://api.helloteca.com/public/variables?vars=tipo_vivienda",
                            headers: getHeadersnew(),
                            type: "GET",
                        }).then(function (response) {
                            if (response.errors && response.errors.length) {
                                reject(parseError(response.data));
                            } else {
                                resolve(response.data.tipo_vivienda);
                            }

                        })
                        .catch(function (response) {
                            reject(parseError(response.data));
                        });
                        /*
                        Restangular
                            .one('/helloteca/score/variables/tipo_vivienda')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.tipo_vivienda);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                        */
                    });
                };
                self.getTipoContrato = function () {
                    return $q(function (resolve, reject) {
                        $.ajax({
                            url: "https://api.helloteca.com/public/variables?vars=tipo_contrato",
                            headers: getHeadersnew(),
                            type: "GET",
                        }) .then(function (response) {
                            if (response.errors && response.errors.length) {
                                reject(parseError(response.data));
                            } else {
                                resolve(response.data.tipo_contrato);
                            }

                        })
                        .catch(function (response) {
                            reject(parseError(response.data));
                        });
                        /*
                        Restangular
                            .one('/helloteca/score/variables/tipo_contrato')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.tipo_contrato);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                        */
                    });
                };
                
                self.getHellotecaBanco = function () {
                    return $q(function (resolve, reject) {
                        $.ajax({
                            url: "https://api.helloteca.com/public/variables?vars=bancos",
                            headers: getHeadersnew(),
                            type: "GET",
                        }).then(function (response) {
                            if (response.errors && response.errors.length) {
                                reject(parseError(response.data));
                            } else {

                                resolve(response.data.bancos.filter(function(item){
                                   
                                    return item.status !== 0;
                                }));

                            }

                        })
                        .catch(function (response) {
                            console.log(response);
                            reject(parseError(response.data));
                        });
                        /*
                        Restangular

                            .one('/helloteca/score/variables/bancos')

                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {

                                    resolve(response.data.bancos.filter(function(item){
                                       
                                        return item.status !== 0;
                                    }));

                                }

                            })
                            .catch(function (response) {
                                console.log(response);
                                reject(parseError(response.data));
                            });
                        */
                    });
                };

                self.getProvinces = function () {
                    return $q(function (resolve, reject) {
                        $.ajax({
                            url: "https://api.helloteca.com/public/variables?vars=provincia",
                            headers: getHeadersnew(),
                            type: "GET",
                        }).then(function (response) {
                            if (response.errors && response.errors.length) {
                                reject(parseError(response.data));
                            } else {
                                resolve(response.data.provincia);
                            }

                        })
                        .catch(function (response) {
                            reject(parseError(response.data));
                        });
                        /*
                        Restangular
                            .one('helloteca/score/variables/provincia')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.provincia);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                        */
                    });
                };

                self.getUsers = function (params) {

                    params = angular.merge({
                        limit: 50,
                        cursor: cursor,
                        order: 'DateUpdating',
                        order_type: 'ASC'
                    }, params || {});

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/score')
                            .customGET('users', params, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getUser = function (id) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/score/users')
                            .customGET(id, {}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.HellotecaUser);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.postUrl = function (url, data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one(url.replace(Restangular.configuration.baseUrl, ''))
                            .customPOST(data, '', {}, getHeaders())
                            .then(function (response) {
                                resolve(response);
                                /*
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                                */
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.whatsappButtonActive = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/score/whatsapp_button')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                return self;
            }
        ]);
}());
