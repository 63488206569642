(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('result/result_final.html',
    '<div class="content success confirm"><div class="title"><div class="row justify-content-center"><div class="title_ico d-none d-md-block"><img src="images/hand-icon.svg"></div><div class="textos text-center text-md-left col-md-9" style="display:contents"><h2><img class="d-inline-block d-md-none position-relative" width="26px" style="top:-8px" src="images/hand-icon.svg"> ¡Enhorabuena! <span class="d-block d-md-inline-block">Ya tienes tu hipoteca</span></h2></div></div></div><div class="row" style="max-width: 580px; margin:0 auto"><div class="col-md-12 pl-3 pr-3 mt-2"><div class="subtitle text-left mt-3 pl-0 pr-0"><p class="text-big bold">Hemos recibido toda la documentación y vamos a iniciar los trámites con el banco seleccionado.</p><p><span class="theme">En un plazo máximo de 48 horas </span>Helloteca o el banco seleccionado se pondrán en contacto contigo para continuar con la tramitación.</p></div></div><div class="col-md-6 pl-3 pr-3 border-right-2"><a ng-click="ctrl.doAction()" class="btn"><img src="images/contact-icon.png" width="32px" class="position-relative"> <span class="theme">Contacta con nosotros</span></a></div><div class="col-md-6 pl-3 pr-3"><a href="https://helloteca.com/blog/" class="btn" target="_blank"><img src="images/blog-icon.png" width="32px" class="position-relative"> <span class="theme">Visita nuestro blog</span></a></div></div></div>');
}]);
})();
