(function () {
    'use strict';
    angular.module('myApp.private5', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private5', {
                    url: '/private5',
                    templateUrl: 'private5/private5.html',
                    controller: 'Private5Ctrl',
                    controllerAs: 'ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {
                        user: [
                            'API', '$state', function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ]
                    }
                });
            }
        ])

        .controller('Private5Ctrl', [
            'DataCollector', 'user', 'API', '$state', '$rootScope',
            function (DataCollector, user, API, $state, $rootScope) {
                var ctrl = this;
                $rootScope.title2 = 'Oferta vinculante';
                ctrl.user = user;
                refreshStatus(user);

                ctrl.userDocs = transformDocuments(ctrl.user.documentos);

                ctrl.parseFile = function (code, index) {
                    return function (response) {
                        refreshStatus(response);
                        return transformDocuments(response.documentos)[code].items[index];
                    };
                };

                ctrl.confirmOffer = function () {
                    API.postUrl(ctrl.offer.texto_url)
                        .then(function () {
                            API.getDocumentMyself()
                                .then(function (user) {
                                    DataCollector.addDataFromRemote(user);
                                    $rootScope.$emit('user-update', user);
                                    if (DataCollector.isAllowedStep('private6')) {
                                        $state.go('private.private6');
                                    } else {
                                        alert(user.step_info);
                                    }
                                });
                        })
                        .catch(function (error) {
                            alert(error);
                        });

                };

                $rootScope.$on('file:uploaded', function (e, document, file, response) {
                    refreshStatus(response);
                    if (ctrl.nbPendingGroups === 0) {
                        confirm('Enhorabuena, ya nos has enviado todos los documentos necesarios.<br><br>Comienza a trabajar con Helloteca y gestionaremos en todos los Bancos con los que tenemos convenio.', [
                            {
                                text: 'Más tarde',
                                class: 'btn-outline',
                                action: angular.noop
                            },
                            {
                                text: 'Enviar petición',
                                class: 'btn-theme',
                                action: function() {
                                    API.postUrl(user.upload_documents.texto_url)
                                        .then(function() {
                                            API.getDocumentMyself()
                                                .then(function(user) {
                                                    DataCollector.addDataFromRemote(user);
                                                    ctrl.status = 'private5_success';
                                                    $scope.$emit('user-update', user);

                                                });
                                        })
                                        .catch(function (error) {
                                            alert(error);
                                        });
                                }
                            }
                        ]);
                    } else {
                        var alertMessage = 'Perfecto! Hemos recibido al documento. Por favor, envíanos ';
                        if (ctrl.nbPendingGroups == 1) {
                            alertMessage = alertMessage + 'el documento obligatorio que falta ';
                        } else {
                            alertMessage = alertMessage + 'los ' + ctrl.nbPendingGroups + ' documentos obligatorios que faltan ';
                        }
                        alertMessage = alertMessage + ' para poder enviar tu petición.';
                        alert(alertMessage);
                    }
                });

                $rootScope.$on('file:removed', function (e, document, file, response) {
                    refreshStatus(response);
                    if (ctrl.nbPendingGroups > 0) {
                        var alertMessage = 'Por favor, envíanos ';
                        if (ctrl.nbPendingGroups == 1) {
                            alertMessage = alertMessage + 'el documento obligatorio que falta ';
                        } else {
                            alertMessage = alertMessage + 'los ' + ctrl.nbPendingGroups + ' documentos obligatorios que faltan ';
                        }
                        alertMessage = alertMessage + ' para poder enviar tu petición.';
                        alert(alertMessage);
                    }
                });


                function transformDocuments(sourceDocuments) {
                    var transformedDocuments = {};

                    angular.forEach(sourceDocuments, function (value, key) {
                        var d = value.code.charAt(value.code.length - 1);
                        var name = value.code.split('_');

                        if (!transformedDocuments[name[0]]) {
                            transformedDocuments[name[0]] = {
                                name: value.name,
                                required: value.required,
                                colClass: 12,
                                items: []
                            };
                        }
                        transformedDocuments[name[0]].items.push(value);
                        transformedDocuments[name[0]].colClass = 12 / transformedDocuments[name[0]].items.length;
                        if (transformedDocuments[name[0]].items.length > 1) {
                            transformedDocuments[name[0]].items.forEach(function (document, key) {
                                document.label = 'Titular ' + (key + 1);
                            });
                        }


                    });
                    return transformedDocuments;
                }

                function refreshStatus(user) {
                    if (user.step5 == 'pending' || user.step5 == 'blocked') {
                        ctrl.nbPendingGroups = 0;
                        var hasRejectedFiles = false;
                        user.documentos.forEach(function (document) {
                            if (document.required && (!document.docs || !document.docs.length)) {
                                ctrl.nbPendingGroups++;
                            }
                            if (document.status !== 'completed') {
                                if (document.docs && document.docs.length) {
                                    document.docs.forEach(function (file) {
                                        if (file.status == 'rejected') {
                                            document.status = 'rejected';
                                            hasRejectedFiles = true;
                                        }
                                    });
                                }
                            }
                        });
                        if (hasRejectedFiles) {
                            ctrl.status = 'private5_danger2';
                        } else if (ctrl.nbPendingGroups > 0) {
                            ctrl.status = 'private5_danger';
                        } else if (user.date_documents_uploaded) {
                            ctrl.status = 'private5_success';
                        } else {
                            ctrl.status = 'private5_warning';
                            // ctrl.statuss = 'private5_success';
                            // ctrl.offers = user.OFERTAS.vinculante || {
                            //     "importe_hipoteca": 150000,
                            //     "cuota": 562,
                            //     "years": 30,
                            //     "interes_type": "variable",
                            //     "interes": "EU +0,85% - 0,95%",
                            //     "valor_compra": 142212,
                            //     "dinero_aportado": 55000,
                            //     "comision_apertura_porcentaje": 0,
                            //     "comision_apertura": 0,
                            //     "impuestos_patrimoniales_porcentaje": 10,
                            //     "impuestos_patrimoniales": 18000,
                            //     "gastos_compra_venta": 1800,
                            //     "gastos_hipoteca": 4412,
                            //     "total": 149212.5,
                            //     "importe_tasacion": 187500,
                            //     "revision": "anual",
                            //     "vinculaciones": ["Cuenta nómina", "Seguro del hogar", "Seguro de vida"],
                            //     "comisiones_cancelacion": ["Cancelación total", "Cancelación Parcial Cero"],
                            //     "texto_boton": "anual",
                            //     "texto_url": "/",
                            // };
                        }

                    } else {
                        ctrl.status = 'completed';
                    }
                }
            }]);


}());