(function () {
    'use strict';
    angular.module('myApp.view3', ['ui.router', 'myApp.dataCollector', 'myApp.API', 'slickCarousel'])

        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider.state('home.view3', {
                    url: '/view3',
                    templateProvider: ['$templateRequest','$stateParams','StepFlows','DataCollector', function ($templateRequest,$stateParams,StepFlows,DataCollector) {
                           var stepConfiguration = StepFlows[$stateParams.stepflow] || {};
                           DataCollector.setStepFlowActive(stepConfiguration.view3.controller);
                           DataCollector.setStateFlowActive('3');                  
                          return $templateRequest(stepConfiguration.view3.template);

                       
                    }],
                    controller: 'ViewCtrl',
                    controllerAs: 'ctrl',
                    data: {
                        isStep: true
                    },
                    onEnter: [
                        '$state', '$stateParams', 'DataCollector', 'API', function ($state, $stateParams, DataCollector, API) {
                            var currentStep = DataCollector.getCurrentStep();
                            var typeView = $stateParams.stepflow;
                            var actualStatus = DataCollector.getData().funel_status;

                            if (currentStep != 'success' && currentStep < 'view3') {
                                $state.go('home.' + DataCollector.getCurrentStep());
                            }

                            if(actualStatus === null || (actualStatus != 'success' && actualStatus < 3)) {
                                var status = currentStep.slice(4);
                                var finalData = {token_id: DataCollector.getUserData().token_id, funel_type: typeView, funel_status: status};
                                
                                API.sendFunel(finalData).then(function() {
                                    DataCollector.addData({funel_type: typeView, funel_status: status});
                                });
                           }
                        }
                    ]
                });
            }
        ])

        .controller('View3Ctrl', [ 
            'DataCollector',
            'banco',
            '$state',
            'API', 
            'regions', 
            'spinnerInterceptor', 
            '$scope',
            '$rootScope',
            'tipoContrato', 
            '$stateParams',
            function( DataCollector,banco, $state, API, regions, spinnerInterceptor, $scope, $rootScope, tipoContrato, $stateParams){
                var ctrl = this;
                //BEGIN MIXPANEL
                mixpanel.track("V2EconomicFormLoaded");
                //END MIXPANEL

                $rootScope.showWpButton = $rootScope.wpButton;
                //BEGIN LuckyOrange
                window._loq = window._loq || []; // ensure queue available
                window._loq.push(["tag", "view3",false,true]); // this will tag, won't star, will overwrite any existing tags
                //END LuckyOrange
                ctrl.state=DataCollector.getStateFlowActive();
                ctrl.bancos = banco;
                ctrl.tipoContrato=tipoContrato;
                ctrl.disabled = '';
                ctrl.stepData = DataCollector.getData();
                var modal_welcome =  new RegExp('[\?&]modal_welcome=([^&#]*)').exec(window.location.href);
           
                if(modal_welcome && modal_welcome[1] && modal_welcome[1]=='true'){
                    if(!DataCollector.getModal_welcome() || DataCollector.getModal_welcome()!=true){
                        DataCollector.setModal_welcome(true);
                        ctrl.modal_welcome=true; 
                    }
                    
                }
                
                
                ctrl.CurrentDate = new Date();
                ctrl.CurrentDateMin = new Date(ctrl.CurrentDate.setFullYear( ctrl.CurrentDate.getFullYear()-18));
                ctrl.CurrentDateMin = ctrl.CurrentDateMin.getFullYear()+ "-" + ("0"+(ctrl.CurrentDateMin.getMonth()+1)).slice(-2) + "-" +("0" + ctrl.CurrentDateMin.getDate()).slice(-2) ;

                ctrl.currentYear = (new Date()).getFullYear();
                ctrl.CurrentDate = new Date();
                ctrl.CurrentDate = ctrl.CurrentDate.getFullYear() + "-" + ("0" + (ctrl.CurrentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + ctrl.CurrentDate.getDate()).slice(-2);
                if (!ctrl.stepData.numero_titulares) {
                    ctrl.stepData.numero_titulares = 1;
                }
                var tit_names=['primer_titular', 'segundo_titular', 'tercer_titular', 'cuarto_titular'];
                for (var j = 1; j <= ctrl.stepData.numero_titulares; j++) {
                    if(!!ctrl.stepData[tit_names[j-1]]){
                    ctrl.stepData['titular'+j+'_contrato']=ctrl.stepData[tit_names[j-1]].contrato;
                    ctrl.stepData['titular'+j+'_antiguedad']=ctrl.stepData[tit_names[j-1]].antiguedad;
                    ctrl.stepData['titular'+j+'_nomina']=ctrl.stepData[tit_names[j-1]].nomina;
                    ctrl.stepData['titular'+j+'_gastos']=ctrl.stepData[tit_names[j-1]].gastos;
                    ctrl.stepData['titular'+j+'_nacimiento']=ctrl.stepData[tit_names[j-1]].nacimiento;
                }
                       
                }
                for (var i = 1; i <= 4; i++) {

                    if (!!ctrl.stepData['titular'+i+'_antiguedad']) {
                        ctrl.stepData['titular'+i+'_antiguedad'] = new Date(ctrl.stepData['titular'+i+'_antiguedad']);
                    }
                    if (!!ctrl.stepData['titular'+i+'_nacimiento']) {
                            ctrl.stepData['titular'+i+'_nacimiento'] = new Date(ctrl.stepData['titular'+i+'_nacimiento']);
                        }
                    
                }

                ctrl.formSubmitted = false;
                $scope.titleOp = null;

                $scope.toggleSelection = function toggleSelection(bancoName) {
                    var idx = $scope.selection.indexOf(bancoName);

                    // Is currently selected
                    if (idx > -1) {
                        $scope.selection.splice(idx, 1);
                        ctrl.disabled = '';
                    }

                    // Is newly selected
                    else {
                        $scope.selection.push(bancoName);

                        if ($scope.selection.length >= 3) {
                            ctrl.disabled = 'disabled';
                        }

                    }
                };

                ctrl.onSubmit = function (form, ctrl) {
                    if (form.$invalid) {
                        ctrl.formSubmitted = true;

                    } else {
                        ctrl.stepData.total_gastos_mensuales=0;
                        ctrl.stepData.total_neto_nominas=0;
                        var timeDiff = Math.abs(Date.now() - ctrl.stepData.titular1_nacimiento.getTime());
                        ctrl.stepData.edad_menor=Math.floor((timeDiff / (1000 * 3600 * 24))/365);
                        for (var i = 1; i <= 4; i++) {
                            if(ctrl.stepData['titular'+1+'_contrato'] == 'siningresos' || ctrl.stepData['titular'+i+'_contrato'] == 'tuslabores' || ctrl.stepData['titular'+i+'_contrato'] == 'otro') {
                                ctrl.stepData['titular'+i+'_nomina'] = 0;
                            }
                            if(!!ctrl.stepData['titular'+i+'_nomina']){
                            ctrl.stepData.total_neto_nominas=ctrl.stepData.total_neto_nominas+ctrl.stepData['titular'+i+'_nomina'];
                            ctrl.stepData.total_gastos_mensuales=ctrl.stepData.total_gastos_mensuales+ ctrl.stepData['titular'+i+'_gastos'];
                            }
                            if(i>1&& (i<=ctrl.stepData.numero_titulares)){
                            
                             if(ctrl.stepData['titular'+i+'_nacimiento']<ctrl.stepData['titular'+(i-1)+'_nacimiento']){
                                timeDiff = Math.abs(Date.now() - ctrl.stepData['titular'+(i-1)+'_nacimiento'].getTime());
                                ctrl.stepData.edad_menor=Math.floor((timeDiff / (1000 * 3600 * 24))/365);
                             }else{
                                timeDiff = Math.abs(Date.now() - ctrl.stepData['titular'+(i-1)+'_nacimiento'].getTime());
                                ctrl.stepData.edad_menor=Math.floor((timeDiff / (1000 * 3600 * 24))/365);
                             }
                            }else{
                                if(i!=1){
                               ctrl.stepData['titular'+i+'_nacimiento']=null;
                               ctrl.stepData['titular'+i+'_antiguedad']=null;
                               ctrl.stepData['titular'+i+'_contrato']=null;
                               ctrl.stepData['titular'+i+'_gastos']=null;
                               ctrl.stepData['titular'+i+'_nomina']=null; 
                           }
                            }
                        }

                        $scope.$emit('loading:show');
                       DataCollector.addData(ctrl.stepData);

                        mixpanel.track("V2EconomicFormClicked");
                       
                        API.sendEconomicData(DataCollector.parseEconomicData(ctrl.stepData))
                            .then(function () {

                                // MIXPANEL getEconomicData
                                var userEconomicData = DataCollector.getEconomicData();
                                mixpanel.people.set({
                                    "Num titulares": userEconomicData.numero_titulares,
                                    "Ingresos mensuales": userEconomicData.total_neto_nominas,
                                    "Importe de otros prestamos": userEconomicData.total_gastos_mensuales,
                                    "Precio de compra": userEconomicData.valor
                                });
                                // MIXPANEL userHouseData
                                 // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    event: "FormularioEconomicos",
                                    tipoFormulario: 'formularioPrescoring'
                                });
                                //END TAG MANAGER
                                
                                if(ctrl.state!=3){
                                    ctrl.state++;
                                    DataCollector.setCurrentStep('view'+ctrl.state);
                                    $state.go('home.view'+ctrl.state);
                                }else{
                                    var typeView = $stateParams.stepflow;
                                    var finalData = {token_id: DataCollector.getUserData().token_id, funel_type: typeView, funel_status: 'success'};
                                
                                    API.sendFunel(finalData).then(function() {
                                        DataCollector.addData({funel_type: typeView, funel_status: 'success'});
                                        DataCollector.setCurrentStep('success');
                                        $state.go('home.success');
                                    });
                                }
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });
                    }
                };

                ctrl.onFieldChange = function (form, field) {
                    var data = {
                        token_id: DataCollector.getToken()
                    };

                    if (form[field].$invalid) {
                        ctrl.formSubmitted = true;
                    } else {
                        data[field] = ctrl.stepData[field];
                        DataCollector.addData(ctrl.stepData);
                        spinnerInterceptor.disableSpinner();
                        API.sendEconomicData(data)
                            .then(function () {
                                spinnerInterceptor.enableSpinner();
                            });
                    }
                };

                // Switch title					
                ctrl.changeTitle = function() {
                    switch (ctrl.stepData.tipo_vivienda) {
                        case 'habitual':
                            $scope.titleOp = 'Compraventa vivienda habitual';
                            break;
                        case '2avivienda':
                            $scope.titleOp = 'Compraventa segunda residencia';
                            break;
                        case 'mejorar':
                            $scope.titleOp = 'Mejora de Hipoteca';
                            break;
                        case 'autopromocion':
                            $scope.titleOp = 'Autopromoción';
                            break;
                        case 'ampliacion':
                            $scope.titleOp = 'Ampliación de hipoteca';
                            break;
                        case 'compra_vivienda_100':
                            $scope.titleOp = 'Compra vivienda al 100%';
                            break;
                        case 'liquidez':
                            $scope.titleOp = 'Hipoteca liquidez';
                            break;
                        case 'extincion_condominio':
                            $scope.titleOp = 'Extinción de condominio';
                            break;
                        case 'cambio_vivienda':
                            $scope.titleOp = 'Cambio de vivienda';
                            break;
                    }  
                };

                ctrl.changeTitle();
            }
        ]);
}());
