(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('success/brokers.html',
    '<div class="modal fade brokers-modal bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="setPasswordModalLabel" aria-hidden="true"><div class="modal-dialog modal-lg" role="document"><div class="modal-content wrapper private"><div class="modal-header"><h2 class="modal-title theme" id="setPasswordModalLabel">Contactar con un asesor financiero</h2><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body"><p class="pb-3">Te podemos poner en contacto con un asesor especializado que te ayude a conseguir la financiación. TIENES MUCHO POR GANAR Y POCO POR PERDER :)</p><div class="d-none d-lg-block"><table width="100%" class="ofertas_prescoring"><thead><tr class="bg-theme-light"><th>Asesor</th><th>Tipo de interés</th><th>Interés</th><th>Descripción</th><th></th></tr></thead><tbody><tr ng-repeat="(code, bank) in modalCtrl.user.data_for_banks"><td>{{bank.title}}</td><td>{{bank.type}}</td><td>{{bank.interest}}</td><td>{{bank.description}}</td><td><a class="btn" href="{{bank.link}}" target="_blank">{{bank.button}}</a></td></tr></tbody></table></div><div class="col d-block d-lg-none"><div class="row" ng-repeat="(code, bank) in modalCtrl.user.data_for_banks"><div class="col"><dl class="mobile-dl ofertas_prescoring"><dt>Asesor</dt><dd><h3 class="broker" style="margin-top:-3px">{{bank.title}}</h3></dd><dt>Tipo de interés</dt><dd>{{bank.type}}</dd><dt>Interés</dt><dd>{{bank.interest}}</dd><dt>Descripción</dt><dd>{{bank.description}}</dd><dt class="text-center float-none full-width"><a class="btn" href="{{bank.link}}" target="_blank">{{bank.button}}</a></dt><hr></dl></div></div></div></div></div></div></div>');
}]);
})();
