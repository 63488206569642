(function () {
    'use strict';
    angular.module('myApp.private6', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private6', {
                    url: '/private6',
                    templateUrl: 'private6/private6.html',
                    controller: 'Private6Ctrl',
                    controllerAs: 'ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {
                        
                        user: [
                            'API', '$state', function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ]
                    }
                    

                });
            }
        ])

        .controller('Private6Ctrl',  [
            'DataCollector', 'user', 'API', '$state','$rootScope', 
            function (DataCollector, user, API, $state, $rootScope) {
                var ctrl = this;
                $rootScope.title2='Tasación';
                ctrl.status='private6_success';
                }
            
        ]);

       
}());