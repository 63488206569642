(function() {
	'use strict';
	angular.module('myApp.view2', ['ui.router', 'myApp.dataCollector', 'myApp.API', 'slickCarousel'])

		   .config([
			   '$stateProvider', function($stateProvider) {
				   $stateProvider.state('home.view2', {
					   url: '/view2',
					   templateProvider: ['$templateRequest','$stateParams','StepFlows','DataCollector', function ($templateRequest,$stateParams,StepFlows,DataCollector) {
                           var stepConfiguration = StepFlows[$stateParams.stepflow];
                           DataCollector.setStepFlowActive(stepConfiguration.view2.controller);
						   DataCollector.setStateFlowActive('2');   
                          return $templateRequest(stepConfiguration.view2.template);

                       
                        }],
					   controller: 'ViewCtrl',
					   controllerAs: 'ctrl',
					   data: {
						   isStep: true
					   },
					   resolve: {
						   regions: ['API', function(API) {
								return API.getCCAAs();
						   }]
					   },
					   onEnter: [
						   '$state', '$stateParams', 'DataCollector', 'API', function($state, $stateParams, DataCollector, API) {
								var currentStep = DataCollector.getCurrentStep();
								var typeView = $stateParams.stepflow;
								var actualStatus = DataCollector.getData().funel_status;

							   if(currentStep != 'success' && currentStep < 'view2') {
								   $state.go('home.' + DataCollector.getCurrentStep());
							   }

							   if(actualStatus === null || (actualStatus != 'success' && actualStatus < 2)) {
									var status = currentStep.slice(4);
									if(status > 2) {
										status = '2';
									}
									var finalData = {token_id: DataCollector.getUserData().token_id, funel_type: typeView, funel_status: status};
									API.sendFunel(finalData).then(function() {
										DataCollector.addData({type: typeView, status: status});
									});
                           		}
							}
					   ]
				   });
			   }
		   ])

		   .controller('View2Ctrl', [
			   'DataCollector',
			   '$state',
			   'API',
			   'regions',
			   'spinnerInterceptor',
               '$scope',
			   '$rootScope',
			   function(DataCollector, $state, API, regions, spinnerInterceptor, $scope, $rootScope) {
				   var ctrl = this;
/*
                   //BEGIN MIXPANEL
                   mixpanel.track("V2OperationFormLoaded");
                   //END MIXPANEL

                   //BEGIN LuckyOrange
                   window._loq = window._loq || []; // ensure queue available
                   window._loq.push(["tag", "view2",false,true]); // this will tag, won't star, will overwrite any existing tags
                   //END LuckyOrange
                   
*/

                    $rootScope.showWpButton = $rootScope.wpButton;
                    
                   ctrl.state=DataCollector.getStateFlowActive();
                   ctrl.regions = regions;
				   ctrl.stepData = DataCollector.getData();
				   $scope.titleOp = null;
                   $scope.showDiv = false;
                   var modal_welcome =  new RegExp('[\?&]modal_welcome=([^&#]*)').exec(window.location.href);
           
                    if(modal_welcome && modal_welcome[1] && modal_welcome[1]=='true'){
                        if(!DataCollector.getModal_welcome() || DataCollector.getModal_welcome()!=true){
                            DataCollector.setModal_welcome(true);
                            ctrl.modal_welcome=true; 
                        }
                        
                    }



                   if(!ctrl.stepData.buscando || ctrl.stepData.buscando=='unknown'||ctrl.stepData.buscando=='propia'){
                   
                    ctrl.stepData.buscando='';
                   }
                   if (!!ctrl.stepData.date_keys) {
                        ctrl.stepData.date_keys = new Date(ctrl.stepData.date_keys);
                    }
                   if(ctrl.stepData.tipo_vivienda=='mejorar'||ctrl.stepData.tipo_vivienda=='ampliacion'){
                        if(!ctrl.stepData.liquidez || ctrl.stepData.tipo_vivienda=='mejorar'){ctrl.stepData.liquidez=0;}

                            ctrl.stepData.capital_pendiente= ctrl.stepData.valor - ctrl.stepData.total_dinero_ahorrado - ctrl.stepData.liquidez;
                            ctrl.stepData.vivienda_nueva='no';


						   }
					if(ctrl.stepData.tipo_vivienda=='ampliacion'){
           
						var capital_final = ctrl.stepData.capital_pendiente + ctrl.stepData.liquidez;

						ctrl.stepData.total_dinero_ahorrado= ctrl.stepData.valor - capital_final;
						ctrl.stepData.vivienda_nueva='no';

					} 
				   if(!ctrl.stepData.tipo_vivienda || ctrl.stepData.tipo_vivienda == 'unknown') {
					   ctrl.stepData.tipo_vivienda = 'habitual';
				   }
                   if(!ctrl.stepData.vivienda_nueva || ctrl.stepData.vivienda_nueva == 'unknown') {
                       ctrl.stepData.vivienda_nueva = 'no';
                   }
				   ctrl.formSubmitted = false;

				   ctrl.onSubmit = function(form, ctrl) {

					   if(form.$invalid) {
						   ctrl.formSubmitted = true;
					   } else {
                            if(ctrl.stepData.buscando===''){
                                ctrl.stepData.buscando='escogida';}
                             if( ctrl.stepData.vivienda_nueva !='si'){
                                ctrl.stepData.date_keys=null;
                            }else{

                            }
                            

                           if(ctrl.stepData.tipo_vivienda=='mejorar'){

                            ctrl.stepData.total_dinero_ahorrado= ctrl.stepData.valor - ctrl.stepData.capital_pendiente;
                            ctrl.stepData.vivienda_nueva='no';


                           } 
                            if(ctrl.stepData.tipo_vivienda=='autopromocion'){

                            ctrl.stepData.valor=ctrl.stepData.valor_obra + ctrl.stepData.valor_terreno;
                            ctrl.stepData.vivienda_nueva='si';


                           }else{
                            ctrl.stepData.valor_obra='';
                             ctrl.stepData.valor_terreno='';

                           } 
                           if(ctrl.stepData.tipo_vivienda=='ampliacion'||ctrl.stepData.tipo_vivienda=='extincion_condominio'){

							var capital_final = ctrl.stepData.capital_pendiente + ctrl.stepData.liquidez;

                            ctrl.stepData.total_dinero_ahorrado= ctrl.stepData.valor - capital_final;
                            ctrl.stepData.vivienda_nueva='no';

                           }
                           if(ctrl.stepData.tipo_vivienda=='liquidez'){

                                ctrl.stepData.total_dinero_ahorrado= 0;
                           }
                           if(ctrl.stepData.tipo_vivienda=='liquidez'||ctrl.stepData.tipo_vivienda=='autopromocion'||ctrl.stepData.tipo_vivienda=='mejorar'||ctrl.stepData.tipo_vivienda=='ampliacion'||ctrl.stepData.tipo_vivienda=='extincion_condominio'){
                            ctrl.stepData.buscando='propia';
                           }
                           if(ctrl.stepData.tipo_vivienda!='cambio_vivienda'){

                            ctrl.stepData.valor_vivienda_actual= null;
                            ctrl.stepData.hipoteca_vivienda_actual= null;
                            ctrl.stepData.valor_hipoteca_vivienda_actual= null;
                       }
                           $scope.$emit('loading:show');
						   DataCollector.addData(ctrl.stepData);

						   mixpanel.track("V2OperationFormClicked");

                           API.sendHouseData(DataCollector.getHouseData())
							  .then(function() {

                                  // MIXPANEL userHouseData
							  	  var userHouseData = DataCollector.getHouseData();

                                  mixpanel.people.set({
                                      "Destino de la hipoteca": userHouseData.tipo_vivienda,
                                      "Comunidad Inmueble": userHouseData.ccaa,
                                      "Precio de compra": userHouseData.valor,
                                      "Aportación": userHouseData.total_dinero_ahorrado
                                  });
                                  // MIXPANEL userHouseData
                                 // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    event: "FormularioOperaciones",
                                    tipoFormulario: 'formularioPrescoring'
                                });
                                //END TAG MANAGER
								 
								if(ctrl.state!=3){
                                    ctrl.state++;
                                    DataCollector.setCurrentStep('view'+ctrl.state);
                                    
                                    $state.go('home.view'+ctrl.state);
                                }else{
                                    DataCollector.setCurrentStep('success');
                                    $state.go('home.success');
                                }
							  })
							  .catch(function(error) {
								  ctrl.error = error;
							  });
					   }
				   };

				   ctrl.onFieldChange = function(form, field) {
					   var data = {
						   token_id: DataCollector.getToken()
					   };

					   if(form[field].$invalid) {
						   ctrl.formSubmitted = true;
					   } else {
						   data[field] = ctrl.stepData[field];
						   DataCollector.addData(ctrl.stepData);
						   spinnerInterceptor.disableSpinner();
						   API.sendHouseData(data)
							  .then(function() {
								  spinnerInterceptor.enableSpinner();
							  });
					   }
				   };

				   // Switch title					
				   ctrl.changeTitle = function() {
                    switch (ctrl.stepData.tipo_vivienda) {
                        case 'habitual':
                            $scope.titleOp = 'Compraventa vivienda habitual';
                            break;
                        case '2avivienda':
                            $scope.titleOp = 'Compraventa segunda residencia';
                            break;
                        case 'mejorar':
                            $scope.titleOp = 'Mejora de Hipoteca';
                            break;
                        case 'autopromocion':
                            $scope.titleOp = 'Autopromoción';
                            break;
                        case 'ampliacion':
                            $scope.titleOp = 'Ampliación de hipoteca';
                            break;
                        case 'compra_vivienda_100':
                            $scope.titleOp = 'Compra vivienda al 100%';
                            break;
                        case 'liquidez':
                            $scope.titleOp = 'Hipoteca liquidez';
                            break;
                        case 'extincion_condominio':
                            $scope.titleOp = 'Extinción de condominio';
                            break;
                        case 'cambio_vivienda':
                            $scope.titleOp = 'Cambio de vivienda';
                            break;
                    }  
                };

					ctrl.changeTitle();
			   }
		   ]);

}());
