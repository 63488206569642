(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('private/infomessage.html',
    '<div class="container-fluid caja {{infomessageCtrl.class}}"><h2 class="text-center">{{infomessageCtrl.title}}</h2><h5 class="text-center" ng-bind-html="infomessageCtrl.description"></h5><button class="btn-theme-private mt-5" ng-click="infomessageCtrl.buttonAction()" ng-if="infomessageCtrl.buttontext">{{infomessageCtrl.buttontext}}</button></div>');
}]);
})();
