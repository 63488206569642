(function () {
    'use strict';
    angular.module('myApp.private4', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private4', {
                    url: '/private4',
                    templateUrl: 'private4/private4.html',
                    controller: 'Private4Ctrl',
                    controllerAs: 'ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {
                        user: [
                            'API', '$state', function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ],
                        tipo_vivienda: ['API', function (API) {
                            return API.getTipoVivienda();
                        }]
                    }
                });
            }
        ])

        .controller('Private4Ctrl', [
            'DataCollector', 'user', 'API', 'tipo_vivienda', '$state','$rootScope', '$timeout',
            function (DataCollector, user, API, tipo_vivienda, $state, $rootScope, $timeout) {
                var ctrl = this;
                var changePromise;

                $rootScope.title2 = 'Oferta personalizada';
                ctrl.user = user;
                ctrl.tiposVivienda = tipo_vivienda;
                refreshStatus(user);

                ctrl.onFieldChange = function () {
                    if(changePromise) {
                        $timeout.cancel(changePromise);
                    }

                    changePromise = $timeout(function() {
                        API.sendUpdateMySelfData(DataCollector.parseOfertaData(ctrl.user.oferta_personalizada))
                            .then(function (user) {
                                ctrl.user.oferta_personalizada = user.oferta_personalizada;
                                ctrl.user.oferta_personalizada.oferta_years = "" + ctrl.user.oferta_personalizada.oferta_years;
                                refreshStatus(user);
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });
                    }, 1000);

                };

                ctrl.requestOffer = function() {
                    API.postUrl(ctrl.user.oferta_personalizada.texto_url, ctrl.user.oferta_personalizada)
                        .then(function() {
                            API.getDocumentMyself()
                                .then(function(user) {
                                    DataCollector.addDataFromRemote(user);
                                    $rootScope.$emit('user-update', user);
                                    //refreshStatus(user);
                                    if(DataCollector.isAllowedStep('private5')) {
                                        $state.go('private.private5');
                                    } else {
                                        ctrl.error = user.step_info;
                                    }
                                });
                        })
                        .catch(function (error) {
                            ctrl.error = error;
                        });
                };

                ctrl.requestBankOffer = function(url) {
                    API.postUrl(url)
                        .then(function() {
                            API.getDocumentMyself()
                                .then(function(user) {
                                    DataCollector.addDataFromRemote(user);
                                    $rootScope.$emit('user-update', user);
                                    if(DataCollector.isAllowedStep('private5')) {
                                        $state.go('private.private5');
                                    } else {
                                        ctrl.error = user.step_info;
                                    }
                                });
                        })
                        .catch(function (error) {
                            ctrl.error = error;
                        });
                };

                function refreshStatus(user) {
                    
                    if(user.step4 == 'pending') {

                        if(user.OFERTAS.length && user.OFERTAS[0].solicitado) {
                            ctrl.offers = user.OFERTAS[0].respondido || [];
                            ctrl.currentOffer = 0;
                            if(user.OFERTAS[0].type == 'oferta_entidad_colaboradora') {
                                ctrl.status = 'private4_waiting_' + ctrl.offers.length;
                            } else {
                                ctrl.status = 'private4_success_' + ctrl.offers.length;
                            }

                        } else if(user.oferta_personalizada.porcentaje_valor_compra > 90) {
                            ctrl.status = 'private4_warning';
                        } else if(user.oferta_personalizada.porcentaje_ingresos > 40) {
                            ctrl.status = 'private4_danger';
                        } else {
                            ctrl.status = 'pending';
                        }

                    } else {
                        ctrl.status = 'completed';
                    }
                }


            }
        ]);


}());
