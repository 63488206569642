(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('private/alert.html',
    '<div class="modal-body text-center pb-0 pt-4"><p>{{alertCtrl.message}}</p></div><div class="modal-footer border-0 pt-0"><button class="btn btn-theme pull-left" ng-click="alertCtrl.close()">Continuar</button></div>');
}]);
})();
