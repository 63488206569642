(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('logout/logout.html',
    '<div class="flex-container"><div class="flex-column"><div class="flex-item">Saliendo de la cuenta...</div></div></div>');
}]);
})();
