(function () {
    'use strict';
    angular.module('myApp.private1', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private1', {
                    url: '/private1',
                    templateUrl: 'private1/private1.html',
                    controller: 'Private1Ctrl',
                    controllerAs: 'private1Ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {

                        user: [
                            'API', '$state', function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ]

                    }


                });
            }
        ])

        .controller('Private1Ctrl', [
            'DataCollector',
            'user',
            'API',
            '$state',
            '$rootScope',
            function (DataCollector, user, API, $state,$rootScope) {
                var ctrl = this;

                $rootScope.title2='Datos titulares';
                ctrl.usuario = user;
                ctrl.offer = user.calc_scoring.oferta;
                ctrl.formSubmitted = false;
                ctrl.phonePattern = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
                ctrl.currentYear = (new Date()).getFullYear();
                ctrl.stepData = user;
                ctrl.stepData.fecha_nacimiento = new Date(ctrl.stepData.fecha_nacimiento);
                ctrl.stepData.titulares = ctrl.stepData.titulares.map(function(titular) {
                    titular.nombre = titular.nombre;
                    titular.fecha_nacimiento = new Date(titular.fecha_nacimiento);
                    return titular;
                });
                ctrl.stepData.num_titulares = parseInt(ctrl.stepData.num_titulares);
                ctrl.CurrentDate = new Date();
                ctrl.CurrentDateMin = new Date(ctrl.CurrentDate.setFullYear( ctrl.CurrentDate.getFullYear()-18));
                ctrl.CurrentDateMin = ctrl.CurrentDateMin.getFullYear()+ "-" + ("0"+(ctrl.CurrentDateMin.getMonth()+1)).slice(-2) + "-" +("0" + ctrl.CurrentDateMin.getDate()).slice(-2) ;


                ctrl.onSubmit = function (form) {
                    ctrl.error = 'error';
                    if (form.$invalid) {
                        console.log(form);
                        console.log('error form');
                        ctrl.formSubmitted = true;

                    } else {
                        ctrl.stepData.titulares[0].email=ctrl.stepData.email;
                        var titulares = ctrl.stepData.titulares;

                        if(titulares.length > ctrl.stepData.num_titulares) {
                            titulares = titulares.splice(-(titulares.length - ctrl.stepData.num_titulares));
                        }
                        API.sendUpdateMySelfData(DataCollector.parseTitularesData(titulares))
                            .then(function (user) {
                                
                                DataCollector.addDataFromRemote(user);
                                $rootScope.$emit('user-update', user);
                                if(DataCollector.isAllowedStep('private2')) {
                                    $state.go('private.private2');
                                } else {
                                    ctrl.error = user.step_info;
                                }
                            })
                            .catch(function (error) {
                                ctrl.error = parseError(error);
                            });
                    }
                };

                ctrl.onNumTitularesChange = function(form) {
                    if(form.num_titulares.$valid) {
                        ctrl.formSubmitted = false;
                        API.sendUpdateMySelfData({num_titulares: ctrl.stepData.num_titulares})
                            .then(function (user) {
                                for(var i = 0; i < ctrl.stepData.num_titulares; i++) {
                                    if(!ctrl.stepData.titulares[i]) {
                                        ctrl.stepData.titulares[i] = user.titulares[i];
                                    }
                                }
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });
                    } else {
                        ctrl.formSubmitted = true;
                    }
                };

                ctrl.parseNIFDocument = function(index) {
                    return function(response) {
                        return response.titulares[index].documentos[0];
                    };
                };

                function parseError(error) {
                    var code = error.split(':')[0];
                    switch (code) {
                        case 'error-form-params-titulares-0-apellido1':
                            return 'Añade el apellido del primer titular';
                        case 'error-form-params-titulares-1-apellido1':
                            return 'Añade el apellido del segundo titular';
                        case 'error-form-params-titulares-2-apellido1':
                            return 'Añade el apellido del tercer titular';
                        case 'error-form-params-titulares-3-apellido1':
                            return 'Añade el apellido del cuarto titular';
                        default:
                            return code;
                    }
                }
            }

        ]);


}());