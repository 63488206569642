(function() {
	'use strict';
	angular.module('myApp.register', ['ui.router', 'myApp.auth'])

		   .config([
			   '$stateProvider', function($stateProvider) {
				   $stateProvider.state('register', {
					   url: '/register',
					   templateUrl: 'register/register.html',
					   controller: 'RegisterCtrl',
					   controllerAs: 'ctrl'
				   });
			   }
		   ])

		   .controller('RegisterCtrl', [
			   'DataCollector', '$state', 'Auth', function(DataCollector, $state, Auth) {
				   var ctrl = this;

				   ctrl.formSubmitted = false;
				   ctrl.stepData = DataCollector.getRegistrationData();
				   ctrl.phonePattern = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;

				   ctrl.onSubmit = function(form) {
					   if(form.$invalid) {
						   ctrl.formSubmitted = true;
					   } else {
						   DataCollector.addData(ctrl.stepData);
						   Auth.register(DataCollector.getRegistrationData())
							   .then(function() {
								   $state.go('private.private1');
							   }, function(error) {
								   ctrl.error = error;
							   });
					   }
				   };
			   }
		   ]);
}());

