(function () {
    'use strict';
    angular.module('myApp.private7', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private7', {
                    url: '/private7',
                    templateUrl: 'private7/private7.html',
                    controller: 'Private7Ctrl',
                    controllerAs: 'private7Ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {

                        user: [
                            'API', '$state', function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ]

                    }


                });
            }
        ])

        .controller('Private7Ctrl', [
            'DataCollector',
            'user',
            'API',
            '$state',
            '$rootScope',
            function (DataCollector, user, API, $state,$rootScope) {
                var ctrl = this;
                $rootScope.title2='Firma';
                ctrl.usuario = user;
                ctrl.formSubmitted = false;
                ctrl.phonePattern = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
                ctrl.currentYear = (new Date()).getFullYear();
                ctrl.stepData = user;
                ctrl.stepData.fecha_nacimiento = new Date(ctrl.stepData.fecha_nacimiento);
                ctrl.CurrentDate = new Date();
                ctrl.CurrentDate = ctrl.CurrentDate.getFullYear() + "-" + ("0" + (ctrl.CurrentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + ctrl.CurrentDate.getDate()).slice(-2);

                if (ctrl.usuario.documentos && ctrl.usuario.documentos !== null) {

                    for (var i = 0; i < ctrl.usuario.documentos.length; i++) {
                        ctrl.usuario.documentos[i].files = [];
                        if (ctrl.usuario.files && ctrl.usuario.files !== null) {
                            for (var j = 0; j < ctrl.usuario.files.length; j++) {
                                if (ctrl.usuario.documentos[i].code === 'dni_nif'&& ctrl.usuario.files[j].doc_type === 'nif_nie') {
                                    ctrl.usuario.documentos[i].files.push(ctrl.usuario.files[j]);
                                    ctrl.usuario.files[j].ext=ctrl.usuario.files[j].doc_url.split('.').pop();
                                }
                            }
                        }
                    }
                }
                ctrl.onSubmit = function (form) {
                    if (form.$invalid) {
                        ctrl.formSubmitted = true;

                    } else {
                        API.sendUpdateMySelfData(DataCollector.parseUpdateEcoData(ctrl.stepData))
                            .then(function () {

                                $state.go('private.private2');
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });
                    }
                };

                ctrl.files = {};

                ctrl.upload = function (document) {
                    ctrl.url = API.UploadFile(document, ctrl.files[document.code])
                        .then(function () {

                            $state.reload();
                        })
                        .catch(function (error) {
                            ctrl.error = error;
                        });

                };

                ctrl.removeFile = function (id) {
                    API.deleteDocument(id)
                        .then(function () {

                            $state.reload();
                        })
                        .catch(function (error) {
                            ctrl.error = error;
                        });

                };
            }

        ])

        .filter('range', function() {
            return function(input, total) {
                total = parseInt(total);

                for (var i=0; i<total; i++) {
                    input.push(i);
                }

                return input;
            };
        });


}());