(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('modals/welcome.html',
    '<div class="modal fade set-welcome-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="setWelcomeModalLabel" aria-hidden="true"><div class="modal-dialog" role="document"><div class="modal-content wrapper private"><div class="modal-header"><h2 class="modal-title theme" id="setWelcomeModalLabel">¡Bienvenido de nuevo!</h2></div><div class="modal-body"><p>Estás muy cerca de obtener tu resultado de viabilidad. Sólo nos hacen falta unos pocos datos más</p></div><div class="modal-footer"><button type="button" class="btn-theme-private prescoring" data-dismiss="modal">Aceptar</button></div></div></div></div>');
}]);
})();
