(function () {
    'use strict';
    angular.module('myApp.success', ['ui.router', 'myApp.dataCollector', 'myApp.API', 'ngSanitize', 'myApp.auth'])

        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider
                    .state('home.success', {
                        url: '/success',
                        templateUrl: 'success/success.html',
                        controller: 'SuccessCtrl',
                        controllerAs: 'ctrl',
                        onEnter: [
                            '$state', 'DataCollector', function ($state, DataCollector) {
                                if (DataCollector.getCurrentStep() !== 'success') {
                                    $state.go('home.' + DataCollector.getCurrentStep());
                                }
                            }
                        ],
                        resolve: {
                            PreScoring: [
                                'API', 'token', function (API, token) {
                                    return API.getPreScoring(token);
                                }
                            ]
                        }
                    });
            }
        ])

        .controller('SuccessCtrl', [
            'DataCollector', 'PreScoring', 'API', '$state', 'Auth', '$scope', '$rootScope', '$q','$window','token',
            function (DataCollector, PreScoring, API, $state, Auth, $scope, $rootScope, $q,$window,token) {
                if(!PreScoring.Scoring) {
                    alert('Ha ocurrido un problema durante el proceso de prescoring. Por favor, revisa los datos que nos has enviado. ');
                    $state.go('home.view1');
                    return;
                }
                var limitscore={'habitual':'90%', 'compra_vivienda_100' : '100%', '2avivienda':'70%','liquidez':'80%', 'autopromocion' : '90%', 'mejorar':'80%','ampliacion':'80%', 'extincion_condominio' : '100%'};
                var ctrl = this;

                $rootScope.showWpButton = $rootScope.wpButton;

                // MIXPANEL DATA
                mixpanel.track("V2PreScoringLoaded", {"Scoring": PreScoring.Scoring.code});
                mixpanel.people.set({
                    "Prescoring Value": PreScoring.Scoring.code
                });
                // END MIXPANEL Success
                ctrl.stepData = DataCollector.getData();
                ctrl.scoring = PreScoring.Scoring;
                ctrl.vivienda = PreScoring.Vivienda;
                ctrl.actions = PreScoring.Scoring.actions;
                console.log(ctrl.actions);
                ctrl.banks = PreScoring.Scoring.data_for_banks;
                ctrl.termsApproved = false;
                ctrl.requireTermsApproved = false;
                ctrl.showBanks = false;
                ctrl.showAvals = false;
                ctrl.showConsultant = false;
                ctrl.showBrokers = false;
                if(PreScoring.Scoring.code === 'mayor_95'&&ctrl.vivienda.tipo_vivienda == 'habitual'){
                    console.log(ctrl.stepData);
                    ctrl.stepData.tipo_vivienda='compra_vivienda_100';
                    API.sendEconomicData(DataCollector.parseTipovivienda(ctrl.stepData))
                            .then(function () {
                                
                                $state.reload();                                    
                            })
                            .catch(function (error) {
                                console.log(error);
                                ctrl.error = error;
                            });    
                                              
                }
                
                    if(!ctrl.stepData.gastos_detalle){
                        ctrl.stepData.gastos_detalle=[];
                        ctrl.stepData.gastos_detalle[0]=[];
                        ctrl.stepData.gastos_detalle[0].capital=0;
                        ctrl.stepData.gastos_detalle[0].cuota=0;
                        ctrl.stepData.gastos_detalle[0].tipo='';
                        ctrl.stepData.gastos_detalle[0].banco='';   
                    }
               
                if(ctrl.scoring.variable_años){
                    ctrl.scoring.variable_anos=ctrl.scoring.variable_años;
                }


                ctrl.titleImage = 'images/hand-icon.svg';
                ctrl.goIconTemplate = 'success/go-icon-menor-95.html';
                ctrl.statusScoring='menor';
                if(ctrl.vivienda.tipo_vivienda == 'habitual') {
                       
                        ctrl.titleOp = 'Compraventa vivienda habitual';
                    } 
                    else if(ctrl.vivienda.tipo_vivienda == '2avivienda') {
                       
                        ctrl.titleOp = 'Compraventa segunda residencia';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'mejorar') {
                       
                        ctrl.titleOp = 'Mejora de Hipoteca';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'ampliacion') {
                       
                        ctrl.titleOp = 'Ampliación de hipoteca'; 
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'compra_vivienda_100') {
                       
                        ctrl.titleOp = 'Compra vivienda al 100%';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'autopromocion') {
                      
                        ctrl.titleOp = 'Autopromoción';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'liquidez') {
                        
                        ctrl.titleOp = 'Hipoteca liquidez';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'extincion_condominio') {
                       
                        ctrl.titleOp = 'Extinción de condominio';
                    }
                if (PreScoring.Scoring.code === 'mayor_95') {
                    ctrl.titleImage = 'images/warning-icon.svg';
                    ctrl.statusScoring='mayor';


                    if(ctrl.vivienda.tipo_vivienda == 'habitual') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-95.html';
                        ctrl.titleOp = 'Compraventa vivienda habitual';
                    } 
                    else if(ctrl.vivienda.tipo_vivienda == '2avivienda') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-70.html';
                        ctrl.titleOp = 'Compraventa segunda residencia';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'mejorar') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-95&mejora.html'; 
                        ctrl.titleOp = 'Mejora de Hipoteca';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'ampliacion') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-95&mejora.html';
                        ctrl.titleOp = 'Ampliación de hipoteca'; 
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'compra_vivienda_100') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-100.html';
                        ctrl.titleOp = 'Compra vivienda al 100%';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'autopromocion') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-100.html';
                        ctrl.titleOp = 'Autopromoción';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'liquidez') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-80-ope.html';
                        ctrl.titleOp = 'Hipoteca liquidez';
                    }
                    else if(ctrl.vivienda.tipo_vivienda == 'extincion_condominio') {
                        ctrl.goIconTemplate = 'success/go-icon-mayor-80-ope.html';
                        ctrl.titleOp = 'Extinción de condominio';
                    }
                    

                    //BEGIN LuckyOrange
                    window._loq = window._loq || []; // ensure queue available
                    window._loq.push(["tag", "success_no_viable",true,true]); // this will tag, star, will overwrite any existing tags
                    //END LuckyOrange
                }else {
                    //BEGIN LuckyOrange
                    window._loq = window._loq || []; // ensure queue available
                    window._loq.push(["tag", "success_si_viable",true,true]); // this will tag,  star, will overwrite any existing tags
                    //END LuckyOrange
                }
                ctrl.add_detalle=function(){
                    if(!ctrl.stepData.gastos_detalle){
                        ctrl.stepData.gastos_detalle[0]=[];
                        ctrl.stepData.gastos_detalle[0].capital=0;
                        ctrl.stepData.gastos_detalle[0].cuota=0;
                        ctrl.stepData.gastos_detalle[0].tipo='';
                        ctrl.stepData.gastos_detalle[0].banco='';
                    }
                    var index=ctrl.stepData.gastos_detalle.length;
                    ctrl.stepData.gastos_detalle[index]=[];
                        ctrl.stepData.gastos_detalle[index].capital=0;
                        ctrl.stepData.gastos_detalle[index].cuota=0;
                        ctrl.stepData.gastos_detalle[index].tipo='';
                        ctrl.stepData.gastos_detalle[index].banco='';

                };
                 ctrl.delete_detalle=function(){
                    var index=ctrl.stepData.gastos_detalle.length-1;
                    ctrl.stepData.gastos_detalle.splice(index,1);                        

                };
                ctrl.confiraval=function(){
                    ctrl.stepData.avalista='si';
                     DataCollector.addData(ctrl.stepData);
                        API.sendEconomicData(DataCollector.parseConfirmaval(ctrl.stepData))
                            .then(function () {
                                $state.go('home.view2');



                                })
                            .catch(function (error) {
                                console.log(error);
                                ctrl.error = error;
                            });


                };
                ctrl.prestamo_personal=function(){
                     
                        DataCollector.addData(ctrl.stepData);

                        API.sendEconomicData(DataCollector.parseRefuerzo(ctrl.stepData))
                            .then(function (response) {
                                ctrl.scoring = response.data.PreScoring.Scoring;
                                 API.postUrl(response.data.PreScoring.Scoring.actions[0].endpoint)
                            .then(function (response) {
                               /*new subida*/
                            
                                 $state.reload();  

                            })
                            .catch(function (response) {

                                // MIXPANEL DATA
                                mixpanel.track("V2PreScoringConvertedError", {"ErrorUrl": action.endpoint});
                                // END MIXPANEL DATA

                                // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'registrationComplete'
                                });
                                //END TAG MANAGER
                                deferred.reject('Ha ocurrido un error. Por favor, vuelve a intentarlo.');
                            });
                                
                                
                            })
                            .catch(function (error) {
                                console.log(error);
                                ctrl.error = error;
                            });

                       
                };
                ctrl.onSubmit = function(form, ctrl) {

                       if(form.$invalid) {
                           ctrl.formSubmitted = true;
                       } else {
                        DataCollector.addData(ctrl.stepData);
                        API.sendEconomicData(DataCollector.parseConfirm(ctrl.stepData))
                            .then(function (response) {
                                ctrl.scoring = response.data.PreScoring.Scoring;
                                API.postUrl(ctrl.actions[0].endpoint)
                            .then(function (response) {
                               /*new subida*/
                            
                               window.location.href=response.data.url_documentos;
                            
                                /*old private zone*/
                                /*
                                Auth.login(DataCollector.getLoginData())
                                    .then(function () {
                                        // MIXPANEL DATA
                                        mixpanel.track("V2PreScoringConverted");
                                        // END MIXPANEL DATA
                                        deferred.resolve();
                                        $state.go('private.private1');

                                    }, function (error) {
                                        // MIXPANEL DATA
                                        mixpanel.track("V2PreScoringConvertedLoginError", {"Error": error});
                                        // END MIXPANEL DATA
                                        if(error == 'La contraseña no es correcta') {
                                            alert('Ya existe un usuario con la misma dirección de correo. Accede con tu contraseña.');
                                            deferred.reject();
                                            $state.go('login');
                                        } else {
                                            deferred.reject(error);
                                        }
                                    });
                                */

                            })
                            .catch(function (response) {

                                // MIXPANEL DATA
                                mixpanel.track("V2PreScoringConvertedError", {"ErrorUrl": action.endpoint});
                                // END MIXPANEL DATA

                                // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'registrationComplete'
                                });
                                //END TAG MANAGER
                                deferred.reject('Ha ocurrido un error. Por favor, vuelve a intentarlo.');
                            });
                                
                                
                            })
                            .catch(function (error) {
                                console.log(error);
                                ctrl.error = error;
                            });

                       }
                };

                ctrl.doAction = function (action,extra) {
                    var deferred = $q.defer();


                    if (ctrl.termsApproved) {
                        $scope.$emit('loading:show');
                        mixpanel.track("V2PreScoringClicked");
                        API.postUrl(action.endpoint+ (extra || ''))
                            .then(function (response) {
                               /*new subida*/
                            
                               window.location.href=response.data.url_documentos;
                            
                                /*old private zone*/
                                /*
                                Auth.login(DataCollector.getLoginData())
                                    .then(function () {
                                        // MIXPANEL DATA
                                        mixpanel.track("V2PreScoringConverted");
                                        // END MIXPANEL DATA
                                        deferred.resolve();
                                        $state.go('private.private1');

                                    }, function (error) {
                                        // MIXPANEL DATA
                                        mixpanel.track("V2PreScoringConvertedLoginError", {"Error": error});
                                        // END MIXPANEL DATA
                                        if(error == 'La contraseña no es correcta') {
                                            alert('Ya existe un usuario con la misma dirección de correo. Accede con tu contraseña.');
                                            deferred.reject();
                                            $state.go('login');
                                        } else {
                                            deferred.reject(error);
                                        }
                                    });
                                */

                            })
                            .catch(function (response) {

                                // MIXPANEL DATA
                                mixpanel.track("V2PreScoringConvertedError", {"ErrorUrl": action.endpoint});
                                // END MIXPANEL DATA

                                // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'registrationComplete'
                                });
                                //END TAG MANAGER
                                deferred.reject('Ha ocurrido un error. Por favor, vuelve a intentarlo.');
                            });
                    } else {
                        ctrl.requireTermsApproved = true;
                        deferred.reject('Por favor, acepta las condiciones de servicio antes de continuar.');
                    }
                    return deferred.promise;
                };

                ctrl.doActionWithApprovedTermsAndAvals = function(action) {
                    ctrl.termsApproved = true;
                    return ctrl.doAction(action,'?type=aval');
                };

                ctrl.doActionWithApprovedTermsAndConsultant = function(action) {
                    ctrl.termsApproved = true;
                    return ctrl.doAction(action,'?type=asesor');
                };
            }
        ]);
}());

