(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('modals/confirm.html',
    '<div class="modal-header pt-3 pb-0" ng-if="confirmCtrl.title"><h5 class="modal-title">{{ confirmCtrl.title }}</h5></div><div class="modal-body text-center pb-0 pt-4"><p ng-bind-html="confirmCtrl.message"></p></div><div class="modal-footer border-0 pt-0"><button class="btn" ng-class="button.class" ng-repeat="button in confirmCtrl.buttons" ng-click="confirmCtrl.clickButton(button)">{{ button.text }}</button></div>');
}]);
})();
