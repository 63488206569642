(function () {
    'use strict';
    angular.module('myApp.view1', ['ui.router', 'myApp.dataCollector', 'myApp.API', 'slickCarousel'])
        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider.state('home.view1', {
                    url: '/view1',
                    templateProvider: ['$templateRequest','$stateParams','StepFlows','DataCollector', function ($templateRequest,$stateParams,StepFlows,DataCollector) {
                           
                           var stepConfiguration = StepFlows[$stateParams.stepflow];
                           
                           DataCollector.setStepFlowActive(stepConfiguration.view1.controller);
                           DataCollector.setStateFlowActive('1');                  
                           return $templateRequest(stepConfiguration.view1.template);

                       
                    }],
                    
                    controller: 'ViewCtrl',
                    controllerAs: 'ctrl',
                    data: {
                        isStep: true
                    },
                    onEnter: [
                        '$stateParams', 'DataCollector', 'API', function($stateParams, DataCollector, API) {
                            var currentStep = DataCollector.getCurrentStep();
                            var typeView = $stateParams.stepflow;
                            var actualStatus = DataCollector.getData().funel_status;
                            
                            if(actualStatus === null || (actualStatus != 'success' && actualStatus < 1)) {
                                var status = currentStep.slice(4);
                                var finalData = {token_id: DataCollector.getUserData().token_id, funel_type: typeView, funel_status: status};

                                API.sendFunel(finalData).then(function() {
                                    DataCollector.addData({funel_type: typeView, funel_status: status});
                                });
                            }
                        }
                    ]
                });
            }
        ])

        .controller('View1Ctrl', [ 
            'DataCollector',
            'banco',
            '$state',
            'API', 
            'regions', 
            'spinnerInterceptor', 
            '$scope', 
            '$rootScope',
            function( DataCollector,banco, $state, API, regions, spinnerInterceptor, $scope, $rootScope){
                $rootScope.showWpButton = false;

                var ctrl = this;
                //BEGIN MIXPANEL
                mixpanel.track("V2ContactFormLoaded");
                //END MIXPANEL

                //BEGIN LuckyOrange
                window._loq = window._loq || []; // ensure queue available
                // https://help.luckyorange.com/article/126-tagging-with-javascript
                window._loq.push(["tag", "view1",false,true]); // this will tag, won't star, and will append the tag
                //END LuckyOrange
                ctrl.state=DataCollector.getStateFlowActive();
                ctrl.formSubmitted = false;
                ctrl.regions = regions;
                ctrl.stepData = DataCollector.getUserData();
                if(!ctrl.stepData.tipo_vivienda || ctrl.stepData.tipo_vivienda===''){
                    var ttipo_vivienda =  new RegExp('[\?&]tipo_vivienda=([^&#]*)').exec(window.location.href);
                    if(ttipo_vivienda!==null && ttipo_vivienda[1]){
                         ctrl.stepData.tipo_vivienda=ttipo_vivienda[1];
                    }else{
                    ctrl.stepData.tipo_vivienda='habitual';
                    }
                }
                ctrl.stepData.terminos_aceptados = !!ctrl.stepData.email;
                $scope.titleOp = null;
                // if(!ctrl.stepData.buscando || ctrl.stepData.buscando == 'unknown') {
                //     ctrl.stepData.buscando = '';
                // }
                // if(ctrl.stepData.valor===null&& ctrl.stepData.total_dinero_ahorrado===0){
                //     ctrl.stepData.total_dinero_ahorrado=null;
                // }
                
                ctrl.phonePattern = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|8|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
                ctrl.currentYear = (new Date()).getFullYear();
                ctrl.range = function (min, max) {
                    var input = [];
                    for (var i = min; i <= max; i++) {
                        input.push(i);
                    }
                    return input;
                };

                ctrl.onSubmit = function (form, ctrl) {

                    if (form.$invalid) {
                        ctrl.formSubmitted = true;
                    } else {
                        $scope.$emit('loading:show');

                        DataCollector.addData(ctrl.stepData);
                        mixpanel.track("V2ContactFormClicked");


                        API.sendUserData(DataCollector.getUserData())
                            .then(function (ret) {
                                var userData = DataCollector.getUserData();

                                // BEGIN Intercom integration
                                try {
                                    /*
                                    // deactivate intercom
                                  var w = window;
                                  var ics = w.intercomSettings;
                                  ics.name = userData.nombre_completo;
                                  ics.email = ret.data.PreScoring.Intercom.intercom_email;
                                  ics.user_id = ret.data.PreScoring.Intercom.intercom_id;
                                  ics.type = 'lead';
                                  activate_intercom();
                                  */
                                }
                                catch (err) {
                                    console.log('Error retrieving ret.data.PreScoring.Intercom.intercom_id');
                                    console.log(err);
                                }
                                // END Intercom integration.


                                // MIXPANEL userData
                                if (projectKeys.mixpanelUserId != userData.email) {
                                    mixpanel.alias(userData.email);
                                    projectKeys.mixpanelUserId = userData.email;
                                }
                                mixpanel.identify(userData.email);
                                mixpanel.people.set({
                                    "$email": userData.email,
                                    "email verified": false,
                                    "$phone": userData.telefono,
                                    "$first_name": userData.nombre_completo,
                                    "phone verified": false,
                                    "PreScoring Updated": new Date(),
                                    "isThirdParty": false,
                                    "ThirdPartyName": ""
                                });

                                mixpanel.people.set_once({
                                    "$created": new Date(),
                                    "PreScoring First Date": new Date()
                                });
                                // END MIXPANEL userData

                                // LuckyOrange User Data
                                var customData = {
                                    'name' : userData.nombre_completo,
                                    'email' : userData.email
                                };

                                window._loq = window._loq || [];
                                window._loq.push(['custom', customData]);
                                // END LuckyOrange
                                 // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    event: "FormularioContacto",
                                    tipoFormulario: 'formularioPrescoring'
                                });
                                //END TAG MANAGER
                                if(ctrl.state!=3){
                                    ctrl.state++;
                                    DataCollector.setCurrentStep('view'+ctrl.state);
                                    
                                    $state.go('home.view'+ctrl.state);
                                }else{
                                    DataCollector.setCurrentStep('success');
                                    $state.go('home.success');
                                }

                                
                            })
                            .catch(function (error) {
                                console.log(error);
                                ctrl.error = error;
                            });
                    }
                };

                // Switch title					
                ctrl.changeTitle = function() {
                    switch (ctrl.stepData.tipo_vivienda) {
                        case 'habitual':
                            $scope.titleOp = 'Compraventa vivienda habitual';
                            break;
                        case '2avivienda':
                            $scope.titleOp = 'Compraventa segunda residencia';
                            break;
                        case 'mejorar':
                            $scope.titleOp = 'Mejora de Hipoteca';
                            break;
                        case 'autopromocion':
                            $scope.titleOp = 'Autopromoción';
                            break;
                        case 'ampliacion':
                            $scope.titleOp = 'Ampliación de hipoteca';
                            break;
                        case 'compra_vivienda_100':
                            $scope.titleOp = 'Compra vivienda al 100%';
                            break;
                        case 'liquidez':
                            $scope.titleOp = 'Hipoteca liquidez';
                            break;
                        case 'extincion_condominio':
                            $scope.titleOp = 'Extinción de condominio';
                            break;
                        case 'cambio_vivienda':
                            $scope.titleOp = 'Cambio de vivienda';
                            break;
                    }  
                };

                ctrl.changeTitle();

            }
        ])

        .directive('confirmEmail', function () {
            return {
                require: "ngModel",
                scope: {
                    otherModelValue: "=confirmEmail"
                },
                link: function (scope, element, attributes, ngModel) {

                    //For DOM -> model validation
                    ngModel.$parsers.unshift(function (value) {
                        var valid = value == scope.otherModelValue;
                        ngModel.$setValidity('match', valid);
                        return valid ? value : undefined;
                    });

                    //For model -> DOM validation
                    ngModel.$formatters.unshift(function (value) {
                        ngModel.$setValidity('match', value == scope.otherModelValue);
                        return value;
                    });
                }
            };
        });
}());