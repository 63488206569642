(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('private/remove-confirm.html',
    '<div class="modal-header pt-3 pb-0"><h5 class="modal-title">{{title}}</h5></div><div class="modal-body text-center pb-0 pt-4"><p>{{message}}</p></div><div class="modal-footer border-0 pt-0"><button class="btn btn-theme btn-outline pull-left" ng-click="no()">Cancelar</button> <button class="btn btn-theme btn-danger pull-right" ng-click="yes()">Eliminar</button></div>');
}]);
})();
