(function () {
    'use strict';
    angular.module('myApp.private3', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private3', {
                    url: '/private3',
                    templateUrl: 'private3/private3.html',
                    controller: 'Private3Ctrl',
                    controllerAs: 'private3Ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {
                        tipo_vivienda: ['API', function (API) {
                            return API.getTipoVivienda();
                        }],
                        banco: [
                            'API',
                            function (API) {
                                return API.getHellotecaBanco();
                            }
                        ],
                        user: [
                            'API', '$state',
                            function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ]
                    }


                });
            }
        ])

        .controller('Private3Ctrl', [
            'DataCollector', 'user', 'API', '$state', 'tipo_vivienda', '$rootScope', '$scope', 'banco',
            function (DataCollector, user, API, $state, tipo_vivienda, $rootScope, $scope, banco) {
                var ctrl = this;
                $rootScope.title2 = "Datos operación";
                ctrl.formSubmitted = false;
                ctrl.stepData = user;
                ctrl.tipo_vivienda = tipo_vivienda;
                ctrl.bancos = banco;
                ctrl.disabled = '';

                ctrl.stepData.operacion.dinero_ahorrado = parseInt(ctrl.stepData.operacion.dinero_ahorrado);

                ctrl.disabled = '';
                if (!ctrl.stepData.operacion.bancos_seleccionados || ctrl.stepData.operacion.bancos_seleccionados == 'unknown') {
                    $scope.selection = [];
                } else {
                    $scope.selection = ctrl.stepData.operacion.bancos_seleccionados.split(",");
                    if ($scope.selection.length >= 3) {
                        ctrl.disabled = 'disabled';
                    }
                }

                $scope.toggleSelection = function toggleSelection(bancoName) {
                    var idx = $scope.selection.indexOf(bancoName);

                    // Is currently selected
                    if (idx > -1) {
                        $scope.selection.splice(idx, 1);
                        ctrl.disabled = '';
                    }

                    // Is newly selected
                    else {
                        $scope.selection.push(bancoName);

                        if ($scope.selection.length >= 3) {
                            ctrl.disabled = 'disabled';
                        }

                    }
                };

                ctrl.onSubmit = function (form) {
                    if ($scope.selection.length) {
                        ctrl.stepData.tres_entidades = $scope.selection.toString();
                        ctrl.bancosError = {required: false};
                    } else {
                        //ctrl.bancosError = {required: true};
                        ctrl.bancosError = {required: false};
                    }
                    if (form.$invalid || ctrl.bancosError.required) {
                        ctrl.formSubmitted = true;

                    } else {
                        ctrl.stepData.operacion.bancos_seleccionados = $scope.selection.toString();
                        API.sendUpdateMySelfData(DataCollector.parseUpdateOpeData(ctrl.stepData))
                            .then(function (user) {
                                DataCollector.addDataFromRemote(user);
                                $rootScope.$emit('user-update', user);
                                if (DataCollector.isAllowedStep('private4')) {
                                    $state.go('private.private4');
                                } else {
                                    ctrl.error = user.step_info;
                                }
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });
                    }
                };
            }

        ]);

}());
