(function () {
    'use strict';
    angular.module('myApp.result', ['ui.router', 'myApp.dataCollector', 'myApp.API'])

        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider
                .state('private.result', {
                    url: '/result',
                    templateUrl: 'result/result.html',
                    controller: 'ResultadosCtrl',
                    controllerAs: 'ctrl',
                    resolve: {
                        bancos: ['$stateParams', 'API', function ($stateParams, API) {
                            return API.getResultBancos();
                        }]
                    }

                })
                .state('private.result_final', {
                    url: '/result_final',
                    templateUrl: 'result/result_final.html',
                    controller: 'Resultados_FinalCtrl',
                    controllerAs: 'ctrl',
                    

                });
            }
        ])

        .controller('ResultadosCtrl', [
            'DataCollector', '$state', 'API', 'bancos','$rootScope', function (DataCollector, $state, API, bancos, $rootScope) {
                var ctrl = this;
                $rootScope.alert=bancos.RESULT;    
                ctrl.bancos=bancos.SCORING;


            }
        ])
        .controller('Resultados_FinalCtrl', [
            'DataCollector', '$state', 'API',  function (DataCollector, $state, API) {
                var ctrl = this;

                ctrl.doAction=function(){
                    
                new Intercom('show');

                };

            }
        ]);


}());