(function () {
    'use strict';
    angular.module('myApp.dataCollector', [])

        .service('DataCollector', [
            '$localStorage', function ($localStorage) {

                var self = this;
                var collectedData = {};
                var currentStep = 'view1';
                var stepFlowActive='1';
                var stateFlowActive='1';
                var modal_welcome=null;

                self.resetData = function () {
                    collectedData = {};
                };

                self.addDataFromRemote = function (data, stepFlow) {

                    //TODO set current step based on the user status
                    if (data.activo) {
                        var step = 'private1';
                        var i;
                        for(i = 1;i <= 7; i++) {
                            if(data['step' + i] == 'pending') {
                                step = 'private' + i;
                            }
                        }
                        self.setCurrentStep(step);
                    } else {
                       
                        self.addData(data.Titulares.primer_titular);
                        self.addData(data.Titulares);
                        self.addData(data.Avales);
                        self.addData(data.Vivienda);
                       
                        if(data.funel && data.funel.status && data.funel.type  ){
                        self.addData({funel_status: data.funel.status, funel_type: data.funel.type});
                        }
                        self.addData({Converted: data.Converted});
                        //User is not authenticated
                        if (collectedData.Converted) {
                            console.log('User Converted');
                        }
                        switch(stepFlow){
                            case 'operations':
                                if (collectedData.ccaa) {
                                self.setCurrentStep('view2');
                                if (collectedData.edad_menor && collectedData.total_neto_nominas) {
                                    self.setCurrentStep('view3');
                                    if (collectedData.email) {
                                        self.setCurrentStep('success');
                                    }
                                }
                                }
                            break;
                            case 'economic':
                                if (collectedData.edad_menor && collectedData.total_neto_nominas) {
                                self.setCurrentStep('view2');
                                if (collectedData.ccaa) {
                                    self.setCurrentStep('view3');
                                    if (collectedData.email) {
                                        self.setCurrentStep('success');
                                    }
                                }
                                }
                            break;
                            default:
                                if (collectedData.email) {
                                self.setCurrentStep('view2');
                                if (collectedData.ccaa) {
                                    self.setCurrentStep('view3');
                                    if (collectedData.edad_menor && collectedData.total_neto_nominas) {
                                        self.setCurrentStep('success');
                                    }
                                }
                                }
                            break;
                        }
                        
                    }
                };
                self.parseRefuerzo=function(data){
                    return{
                        token_id: self.getToken(),
                        prestamo_personal: data.prestamo_personal,
                    };
                };
                self.parseConfirmaval=function(data){
                    return{
                        token_id: self.getToken(),
                        avslista: data.avalista,
                    };
                };
                self.parseTipovivienda=function(data){
                    return{
                        token_id: self.getToken(),
                        tipo_vivienda: data.tipo_vivienda,
                    };
                };
                self.parseConfirm=function(data){
                    return {
                        token_id: self.getToken(),
                        email: data.email,
                        telefono: data.telefono,
                        total_gastos_mensuales: data.total_gastos_mensuales || 0,
                        valor: data.valor,
                        dinero_ahorrado: data.dinero_ahorrado,
                        gastos_detalle: data.gastos_detalle.map(self.parseDetallesData),
                        bancos: data.bancos||null,
                        comentario:data.comentario||null,
                        contact_time_zone:data.contact_time_zone || null,

                    };
                };
                self.parseDetallesData = function (detalle) {

                    return {
                        capital: detalle.capital || 0,
                        cuota: detalle.cuota || 0,
                        tipo: detalle.tipo || null,
                        banco: detalle.banco || null,

                    };
                };
                self.parseTitularesData = function (titulares) {
                    return {
                        num_titulares: titulares.length,
                        titulares: titulares.map(self.parseTitularData)
                    };
                };
                self.parseTitularData = function (titular) {

                    var fecha_nacimiento = titular.fecha_nacimiento.getFullYear() + "-" + ("0" + (titular.fecha_nacimiento.getMonth() + 1)).slice(-2) + "-" + ("0" + titular.fecha_nacimiento.getDate()).slice(-2);
                    /*var nameParts = titular.nombre.split(' ').filter(function(part) {return !!part;});
                    var name, surname1, surname2;

                    switch (nameParts.length) {
                        case 0:
                        case 1:
                            name = titular.nombre;
                            break;
                        case 2:
                            name = nameParts[0];
                            surname1 = nameParts[1];
                            break;
                        default:
                            surname2 = nameParts.pop();
                            surname1 = nameParts.pop();
                            name = nameParts.join(' ');
                    }*/

                    return {
                        apellido2: titular.apellido2||null,
                        apellido1: titular.apellido1,
                        nombre: titular.nombre,
                        nif_nie: titular.nif_nie,
                        fecha_nacimiento: fecha_nacimiento || null,
                        email: titular.email,
                        telefono: titular.telefono,
                        num_children: titular.num_children || 0,

                    };
                };
                self.parseTitularesEconomicData = function (titulares) {
                    return {
                        datos_economicos: {
                            titulares: titulares.map(self.parseTitularEconomicData)
                        }
                    };
                };

                self.parseTitularEconomicData = function (titular) {
                    var fecha = titular.fecha_incorporacion || null;
                    if (titular.fecha_incorporacion) {
                        fecha = fecha.getFullYear() + "-" + ("0" + (fecha.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha.getDate()).slice(-2);
                    }
                    return {
                        estado_civil: titular.estado_civil,
                        tipo_contrato: titular.tipo_contrato,
                        fecha_incorporacion: fecha,
                        neto_mensual: titular.neto_mensual,
                        numero_pagas: titular.numero_pagas || null,
                        otros_ingresos: titular.otros_ingresos,
                        gastos_mensuales: titular.gastos_mensuales,
                        entidad_bancaria: titular.entidad_bancaria
                    };
                };
                self.parseUpdateOpeData = function (params) {
                    return {
                        operacion: {
                            tipo_vivienda: params.operacion.tipo_vivienda,
                            valor: params.operacion.valor,
                            dinero_ahorrado: params.operacion.dinero_ahorrado,
                            cp: params.operacion.cp || null,
                            direccion: params.operacion.direccion,
                            bancos_seleccionados: params.operacion.bancos_seleccionados,
                            vivienda_nueva: params.operacion.vivienda_nueva || 'si',
                            contrato_arras: params.operacion.contrato_arras || 'no',
                        }

                    };
                };

                self.parseOfertaData = function (params) {
                    return {
                        oferta_type: params.oferta_type,
                        oferta_years: params.oferta_years,
                        dinero_ahorrado: params.dinero_aportado,
                        valor: params.valor_compra
                    };
                };
                self.addData = function (data) {
                    angular.extend(collectedData, data);
                };

                self.getData = function () {
                    return collectedData;
                };

                self.getDataField = function (field) {
                    return collectedData[field];
                };

                self.getUserData = function () {
                    var data= {
                        nombre_completo: collectedData.name || collectedData.nombre_completo,
                        email: collectedData.email || collectedData.user,
                        telefono: collectedData.telefono || collectedData.mobile,
                        tipo_vivienda: collectedData.tipo_vivienda,
                        // buscando: collectedData.buscando,
                        // valor: collectedData.valor ,
                        // total_dinero_ahorrado: collectedData.total_dinero_ahorrado,
                        token_id: self.getToken()
                    };
                    if (collectedData.ccaa) {
                        data.ccaa = collectedData.ccaa;
                    }
                    return data;
                };

                self.getHouseData = function () {
                    var data = {
                        tipo_vivienda: collectedData.tipo_vivienda || 'habitual',
                        valor: collectedData.valor || 10000,
                        valor_terreno: collectedData.valor_terreno || null,
                        valor_hipoteca_vivienda_actual:collectedData.valor_hipoteca_vivienda_actual || null,
                        hipoteca_vivienda_actual:collectedData.hipoteca_vivienda_actual || null,
                        valor_vivienda_actual:collectedData.valor_vivienda_actual || null,
                        valor_obra: collectedData.valor_obra || null,
                        capital_pendiente: collectedData.capital_pendiente || null,
                        total_dinero_ahorrado: collectedData.total_dinero_ahorrado || 0,
                        liquidez: collectedData.liquidez || 0,
                        buscando: collectedData.buscando||'escogida',
                        vivienda_nueva: collectedData.vivienda_nueva||'si',
                        direccion: collectedData.direccion || null,
                        localidad: collectedData.localidad || null,
                        avalista: collectedData.avalista,
                        avalista_hipoteca_pendiente: collectedData.avalista_hipoteca_pendiente,
                        avalista_hipoteca: collectedData.avalista_hipoteca,
                        avalista_valor_vivienda: collectedData.avalista_valor_vivienda,
                        avalista_tipo: collectedData.avalista_tipo,



                        token_id: self.getToken()
                    };
                    if (collectedData.ccaa) {
                        data.ccaa = collectedData.ccaa;
                    }
                    if (collectedData.otros_prestamos) {
                        data.otros_prestamos = collectedData.otros_prestamos;
                    }
                    if (collectedData.terreno) {
                        data.terreno = collectedData.terreno;
                    }
                    if (collectedData.obra) {
                        data.obra = collectedData.obra;
                    }
                    if (collectedData.date_keys) {
                        var fecha1=collectedData.date_keys;
                        fecha1 = fecha1.getFullYear() + "-" + ("0" + (fecha1.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha1.getDate()).slice(-2);
                        
                        data.date_keys = fecha1;
                    }
                    return data;
                };
                self.parseEconomicData = function (titular) {
                    var fecha1 = titular.titular1_antiguedad || null;
                    var fecha2 = titular.titular2_antiguedad || null;
                    var fecha3 = titular.titular1_nacimiento || null;
                    var fecha4 = titular.titular2_nacimiento || null;
                    

                    if (fecha1) {
                        fecha1 = fecha1.getFullYear() + "-" + ("0" + (fecha1.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha1.getDate()).slice(-2);
                        
                    }
                    if (fecha2) {
                        fecha2 = fecha2.getFullYear() + "-" + ("0" + (fecha2.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha2.getDate()).slice(-2);
                    }
                    if (fecha3) {
                        fecha3 = fecha3.getFullYear() + "-" + ("0" + (fecha3.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha3.getDate()).slice(-2);
                    }
                    if (fecha4) {
                        fecha4 = fecha4.getFullYear() + "-" + ("0" + (fecha4.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha4.getDate()).slice(-2);
                    }

                    return {
                        numero_titulares: collectedData.numero_titulares || 1,
                        edad_menor: collectedData.edad_menor || 18,
                        total_neto_nominas: collectedData.total_neto_nominas || 0,
                        numero_pagas: 12,
                        total_gastos_mensuales: collectedData.total_gastos_mensuales || 0,
                        oferta_type:collectedData.oferta_type || null,
                        oferta_years:collectedData.oferta_years || null,
                        bancos_evaluando:collectedData.bancos_evaluando || null,
                        contact_time_zone:collectedData.contact_time_zone || null,
                        token_id: self.getToken(),
                        titular1_contrato:collectedData.titular1_contrato,
                        titular2_contrato:collectedData.titular2_contrato|| null,
                        titular1_antiguedad:fecha1|| null,
                        titular2_antiguedad:fecha2|| null,
                        titular1_nomina:collectedData.titular1_nomina,
                        titular2_nomina:collectedData.titular2_nomina|| null,
                        titular1_gastos:collectedData.titular1_gastos,
                        titular2_gastos:collectedData.titular2_gastos|| null,
                        titular1_nacimiento:fecha3||null,
                        titular2_nacimiento:fecha4||null,
                    };
                };
                self.getEconomicData = function () {
                    return {
                        numero_titulares: collectedData.numero_titulares || 1,
                        edad_menor: collectedData.edad_menor || 18,
                        total_neto_nominas: collectedData.total_neto_nominas || 0,
                        total_gastos_mensuales: collectedData.total_gastos_mensuales || 0,
                        oferta_type:collectedData.oferta_type,
                        oferta_years:collectedData.oferta_years,
                        bancos_evaluando:collectedData.bancos_evaluando || null,
                        contact_time_zone:collectedData.contact_time_zone,
                        token_id: self.getToken(),
                        titular1_contrato:collectedData.titular1_contrato,
                        titular2_contrato:collectedData.titular2_contrato|| null,
                        titular1_antiguedad:collectedData.titular1_antiguedad|| null,
                        titular2_antiguedad:collectedData.titular2_antiguedad|| null,
                        titular1_nomina:collectedData.titular1_nomina,
                        titular2_nomina:collectedData.titular2_nomina|| null,
                        titular1_gastos:collectedData.titular1_gastos,
                        titular2_gastos:collectedData.titular2_gastos|| null,
                        titular1_nacimiento:collectedData.titular1_nacimiento||null,
                        titular2_nacimiento:collectedData.titular2_nacimiento||null,
                    };
                };

                self.getRegistrationData = function () {
                    return {
                        name: collectedData.name || collectedData.nombre,
                        email: collectedData.user || collectedData.email,
                        mobile: collectedData.mobile || collectedData.telefono,
                        password: collectedData.password,
                        type: 'userpassword'
                    };
                };

                self.getLoginData = function () {
                    return {
                        user: collectedData.user || collectedData.email,
                        password: collectedData.password || collectedData.telefono,
                        type: 'userpassword'
                    };
                };
                self.setStepFlowActive = function (step) {
                    stepFlowActive = step;
                };

                self.getStepFlowActive = function () {
                    return stepFlowActive;
                };
                self.setStateFlowActive = function (step) {
                    stateFlowActive = step;
                };

                self.getStateFlowActive = function () {
                    return stateFlowActive;
                };
                

                self.setCurrentStep = function (step) {
                    currentStep = step;
                };

                self.getCurrentStep = function () {
                    return currentStep;
                };
                self.setModal_welcome = function (step) {
                    modal_welcome = step;
                };

                self.getModal_welcome = function () {
                    return modal_welcome || null;
                };

                self.isAllowedStep = function (step) {
                    var currentStep = self.getCurrentStep();
                    return currentStep == 'success' || currentStep == 'confirm' || currentStep >= step;
                };

                self.setToken = function (value) {
                    $localStorage.ds_noauth_token = value;
                };

                self.getToken = function () {
                    return $localStorage.ds_noauth_token || null;
                };

                self.deleteDSToken = function() {

                    $localStorage.dstoken = null;
                };

                return self;
            }
        ]);
}());

