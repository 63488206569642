(function () {
    'use strict';
    angular.module('myApp.private2', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('private.private2', {
                    url: '/private2',
                    templateUrl: 'private2/private2.html',
                    controller: 'Private2Ctrl',
                    controllerAs: 'private2Ctrl',
                    data: {
                        isStep: true
                    },
                    resolve: {

                        estadoCivil: [
                            'API',
                            function (API) {
                                return API.getEstadoCivil();
                            }
                        ],
                        banco: [
                            'API',
                            function (API) {
                                return API.getHellotecaBanco();
                            }
                        ],
                        tipoContrato: [
                            'API',
                            function (API) {
                                return API.getTipoContrato();
                            }
                        ],
                        user: [
                            'API', '$state', function (API, $state) {
                                return API.getDocumentMyself()
                                    .catch(function () {
                                        $state.go('logout');
                                    });
                            }
                        ]

                    }


                });
            }
        ])

        .controller('Private2Ctrl', [
            'DataCollector',
            'user',
            'API',
            '$state',
            'estadoCivil',
            'banco',
            'tipoContrato',
            '$rootScope',
            function (DataCollector, user, API, $state, estadoCivil, banco, tipoContrato, $rootScope) {
                var ctrl = this;
                $rootScope.title2 = 'Datos economicos';
                ctrl.formSubmitted = false;
                ctrl.phonePattern = /^(\+34|0034|34)?[\s|\-|\.]?[6|7|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/;
                ctrl.currentYear = (new Date()).getFullYear();
                ctrl.estadoCivil = estadoCivil;
                ctrl.banco = banco;
                ctrl.tipoContrato = tipoContrato;
                ctrl.stepData = user.datos_economicos;
                ctrl.num_titulares = parseInt(user.num_titulares);
                ctrl.CurrentDate = new Date();
                ctrl.CurrentDate = ctrl.CurrentDate.getFullYear() + "-" + ("0" + (ctrl.CurrentDate.getMonth() + 1)).slice(-2) + "-" + ("0" + ctrl.CurrentDate.getDate()).slice(-2);
                ctrl.usuario = user;
                ctrl.conditionalFields= {};
                

                for (var i = 0; i < ctrl.stepData.titulares.length; i++) {
                    if (!!ctrl.stepData.titulares[i].fecha_incorporacion) {
                        ctrl.stepData.titulares[i].fecha_incorporacion = new Date(ctrl.stepData.titulares[i].fecha_incorporacion);
                    }
                }

                ctrl.onChangeContractType = function(titular, $i) {
                    if(!ctrl.conditionalFields[$i]){
                        ctrl.conditionalFields[$i] = {};
                    }
                                       
                    switch (titular.tipo_contrato) {
                        case 'autonomo':

                            ctrl.conditionalFields[$i].fecha_incorporacion = 'Fecha de alta';
                            ctrl.conditionalFields[$i].neto_mensual = 'Casilla 412 de tu último IRPF anual';
                            ctrl.conditionalFields[$i].numero_pagas = false;
                            if(!titular.numero_pagas) titular.numero_pagas = 1;
                            break;
                        case 'rentista':
                            ctrl.conditionalFields[$i].fecha_incorporacion = false;
                            ctrl.conditionalFields[$i].neto_mensual = 'Ingresos mensuales netos';
                            ctrl.conditionalFields[$i].numero_pagas = false;
                            if(!titular.numero_pagas) titular.numero_pagas = 12;
                            if(!titular.fecha_incorporacion) titular.fecha_incorporacion = new Date();
                            break;
                        case 'pensionista_jubilado':
                            ctrl.conditionalFields[$i].fecha_incorporacion = false;
                            ctrl.conditionalFields[$i].neto_mensual = 'Pensión neta mensual';
                            ctrl.conditionalFields[$i].numero_pagas = '¿Cuál es el número de pagas al año?';
                            if(!titular.numero_pagas) titular.numero_pagas = 14;
                            if(!titular.fecha_incorporacion) titular.fecha_incorporacion = new Date();
                            break;
                        case 'siningresos':
                        case 'tuslabores':
                        case 'otro':
                            ctrl.conditionalFields[$i].fecha_incorporacion = false;
                            ctrl.conditionalFields[$i].neto_mensual = false;
                            ctrl.conditionalFields[$i].numero_pagas = false;
                            if(!titular.numero_pagas) titular.numero_pagas = 12;
                            titular.neto_mensual = 0;
                            if(!titular.fecha_incorporacion) titular.fecha_incorporacion = new Date();
                            break;
                        default:
                            ctrl.conditionalFields[$i].fecha_incorporacion = 'Fecha de incorporación al trabajo';
                            ctrl.conditionalFields[$i].neto_mensual = '¿Cuál es su nómina neta mensual?';
                            ctrl.conditionalFields[$i].numero_pagas = '¿Cuál es el número de pagas al año?';
                            if(!titular.numero_pagas) titular.numero_pagas = 12;
                    }
                    
                };
                var j;
                for(j = 0; j < ctrl.num_titulares; j++) {
                    ctrl.onChangeContractType(ctrl.stepData.titulares[j],j);
                }

                ctrl.boolToStr = function (value) {
                    return value ? 'SÍ' : 'NO';
                };
                ctrl.onSubmit = function (form) {
                    ctrl.error = '';
                    if (form.$invalid) {
                        ctrl.formSubmitted = true;

                    } else {
                        API.sendUpdateMySelfData(DataCollector.parseTitularesEconomicData(ctrl.stepData.titulares))
                            .then(function (user) {
                                DataCollector.addDataFromRemote(user);
                                $rootScope.$emit('user-update', user);
                                if(DataCollector.isAllowedStep('private3')) {
                                    $state.go('private.private3');
                                } else {
                                    ctrl.error = user.step_info;
                                }
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });
                    }
                };
                ctrl.parseRentaDocument = function (index) {
                    return function (response) {
                        return response.datos_economicos.titulares[index].documentos[0];
                    };
                };
                ctrl.parseNominaDocument = function (index) {
                    return function (response) {
                        return response.datos_economicos.titulares[index].documentos[1];
                    };
                };
            }

        ]);


}());