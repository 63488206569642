(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('reset_password/reset_password_success.html',
    '<div class="flex-container"><div class="flex-column"><div class="flex-item"><img src="images/logo.svg" alt="Helloteca" width="200px"></div><div class="flex-item"><h4 class="bold">Su contraseña a sido enviada.</h4></div><div class="flex-item"><p>Por favor, pulse <a ui-sref="login">aqui</a> para acceder al la pagina de login.</p></div></div></div>');
}]);
})();
